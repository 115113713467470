import React, { useEffect, useState } from 'react';
import './KenyaLogin.css';
import { Col, Container, Nav, Row, Spinner } from 'react-bootstrap';
import logo from './Assets/logo.png';
import laptop from './Assets/login.svg';
import { useNavigate } from 'react-router-dom';
import { LoginApi } from './Api';
import axios from 'axios';
import { toast } from 'react-toastify';

const KenyaLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setIsValid(true);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setIsValid(true);
  };

  const loginFun = async (e) => {
    e.preventDefault();
      setLoading(true);

    const logObj = {
      login_id: email,
      password: password,
    };
    try {
      const response = await axios.post(LoginApi, logObj);


      if (response.data === 'FAILED') {
        toast.error('Invalid login details!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }else if (response.data === 'BLOCKED') {
        toast.warning('Login blocked contact admin!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      else if (response.data !== null && response.data.user && response.data.token) {
        const { user, token } = response.data;

        const userDataWithLoginId = {
          ...user,
          login_id: email,
        };

        axios.defaults.headers.common['token'] = token;

        // Store user data in session storage
        sessionStorage.setItem('user', JSON.stringify(userDataWithLoginId));
        sessionStorage.setItem('token', response.data.token);

        navigate('/otp');
      } else {
        toast.error('Invalid login details !!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      alert(`Server Error: ${error}`);
    }
    finally {
      setLoading(false); 
    }
  };

  const checkLogin = () => {
    const getSessionval = sessionStorage.getItem('user');
    if (getSessionval) {
      navigate('/otp');
    }
  };

  useEffect(() => {
    sessionStorage.clear()
    checkLogin();

  }, []);
  const handleForgotPass = () => {
    navigate('/forgotPassword');
  };

  return (
    <Container fluid>
      <Row >
        <Col style={{ display: "flex" }} className='ps-md-5 ps-2 pt-md-4 pt-2 mb-4'>
          <img src={logo}  style={{ width: "75px", height: "65px" }} />
          <h2 className='ps-3 fs-1' style={{ display:"flex",color: "#000000", fontFamily: "Inder, var(--default-font-family)", lineHeight: "30px",alignItems:"center",justifyContent:"center",textShadow:"2px 4px 10px gray" }}>THE KENYA NATIONAL EXAMINATION COUNCIL </h2>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
            <img src={laptop} className='w-100 mb-md-0 mb-4 p-5' />
          </Col>
          <Col md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className='login-container mb-4 mb-md-0'>
              <div className='login-form shadow mb-5 mb-md-3' style={{ border: "3px solid #ffe500", borderRadius: "15px" }}>
                <form
                  onSubmit={loginFun}
                >
                  <div className='input'>

                    <h1>Login</h1>
                    <input id='input' type="email" placeholder='Email Id'
                      onChange={handleEmail}
                      required />

                    <input id='input1' type="password" placeholder='Password'
                      onChange={handlePassword}
                      required />

                    <div className='pt-3' style={{ display: "flex", justifyContent: "end" }}>
                      <Nav.Link style={{ color: "blue" }}
                      // onClick={() => setForgetshow(true)}
                      ><small className='forgot'  onClick={handleForgotPass}>Forgot Password?</small></Nav.Link>
                    </div>

                    <div className='login-button'>
                      <button type='submit' className='login-btn' disabled={loading}>
                        {loading ? <Spinner animation='border' size='sm' variant="primary"/> : <b>LOGIN</b>}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div>
        <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
      </div>
    </Container>
  )
}

export default KenyaLogin