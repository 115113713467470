import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import KenyaHeader from './KenyaHeader';
import axios from 'axios';
import { GenerateCSVPendingCountSchoolWise, GeneratedSchoolListApi, GeneratedSchoolTableApi, GetLastCertificateNo, PendingCountSchoolWise, PrintResultBySchoolPendingCert, ReportgenerateCSVPendingCountStudentWise, ReportgenerateCSVPendingListSchoolWise, ReportgetPendingListSchoolWise, getExportedReportAPIURL } from './Api';
import { useNavigate } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import { Modal, Button } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { toast } from 'react-toastify';

const ReportPdSchool = () => {

    const [school_master, setSchool_master] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [reportType, setReportType] = useState("");
    const [selectedFileType, setSelectedFileType] = useState("");
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user.userId;
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        // Fetch school data
        axios
          .get(PendingCountSchoolWise, {
            headers: {
              token: sessionStorage.getItem('token'),
            },
          })
          .then((res) => {
            
            if (res.data === 'UNAUTHORIZED') {
              navigate('/');
            } else if (res.data !== null) {
              setSchool_master(res.data);
            }
          })
          .catch((err) => {
            toast.error('Failed to fetch schools, try again', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
      }, []);
   
    useEffect(() => {
        if (selectedSchool) {
          // Fetch data for the selected school
          axios
            .get(`${ReportgetPendingListSchoolWise}?schoolName=${selectedSchool}`, {
              headers: {
                token: sessionStorage.getItem('token'),
              },
            })
            .then((res) => {
                console.log("api loh...",res.data);
              if (res.data === 'UNAUTHORIZED') {
                navigate('/');
              } else if (res.data !== null) {
                 setData(res.data); 
              }// Assuming the response data is an array of objects
            })
            .catch((err) => {
              toast.error('Failed to fetch data, try again', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            });
        }
      }, [selectedSchool]);


    const handleDownload = () => {

      if (!selectedFileType || !reportType) {
        // Check if either FileType or ReportType is not selected
        toast.error("Please select all fields", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return; // Stop execution if either FileType or ReportType is not selected
      }
    
      if (reportType === "School Wise" && !selectedSchool) {
        // Check if School is not selected when ReportType is School Wise
        toast.error("Please select a school", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return; // Stop execution if School is not selected when ReportType is School Wise
      }
    
    
      if (reportType === "All") {
        setLoading(true);
        axios
          .get(ReportgenerateCSVPendingCountStudentWise, {
            headers: {
              token: sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("api../..", res.data);
            const pdfFileName = res.data;
            if (pdfFileName === "NOTFOUND") {
              toast.warn("Data Not Found", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else if (pdfFileName) {
              const pdfUrl = getExportedReportAPIURL + pdfFileName;
              window.open(pdfUrl, "_blank");
              setPdfUrl(pdfUrl);
            } else if (pdfFileName === "UNAUTHORIZED") {
              navigate("/");
            } else {
              console.error("Invalid PDF URL");
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      } else if (reportType === "School Wise") {
        setLoading(true);
        axios
          .get(
            `${ReportgenerateCSVPendingListSchoolWise}?schoolName=${selectedSchool}`,
            {
              headers: {
                token: sessionStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log("api../..", res.data);
            const pdfFileName = res.data;
            if (pdfFileName === "NOTFOUND") {
              toast.warn("Data Not Found", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else if (pdfFileName) {
              const pdfUrl = getExportedReportAPIURL + pdfFileName;
              window.open(pdfUrl, "_blank");
              setPdfUrl(pdfUrl);
            } else if (pdfFileName === "UNAUTHORIZED") {
              navigate("/");
            } else {
              console.error("Invalid PDF URL");
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    };
  

    return (
        <Container fluid>
            <Row>
                <KenyaHeader />
            </Row>
            <Container>
                <Row className='pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                    <Row className='m-0 p-0 px-md-5 pt-3'>
                        <Col style={{ display: "flex"}}>
                            <h2 className='ps-1' style={{borderLeft:"3px solid blue"}}>Report Pending Certificate</h2>
                          
                        </Col>
                    </Row>


                    <Row className="m-0 p-0 pt-3">
            <Col md={3}></Col>
            <Col md={6}>
              <Form.Label>
                <b>File Type :</b>
              </Form.Label>
              <Form.Select
                required
                aria-label="Default select example"
                onChange={(e) => setSelectedFileType(e.target.value)}
              >
                <option disabled selected>
                  Select...
                </option>
                <option>CSV</option>
              </Form.Select>
            </Col>
            <Col md={3}></Col>
          </Row>

          <Row className="m-0 p-0 pt-3">
            <Col md={3}></Col>
            <Col md={6}>
              <Form.Label>
                <b>Option :</b>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setReportType(e.target.value)}
              >
                <option disabled selected>
                  Select...
                </option>
                <option value="All">All</option>
                <option value="School Wise">School Wise</option>
              </Form.Select>
            </Col>
            <Col md={3}></Col>
          </Row>

          {reportType === "School Wise" && (
            <Row className="m-0 p-0 pt-3">
              <Col md={3}></Col>
              <Col md={6}>
                <Form.Label>
                  <b>Select School :</b>
                </Form.Label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  options={school_master}
                  getOptionLabel={(option) => option.schoolName}
                  value={
                    school_master.find(
                      (item) => item.schoolName === selectedSchool
                    ) || null
                  }
                  onChange={(e, newValue) =>
                    setSelectedSchool(newValue ? newValue.schoolName : "")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: "100%" }}
                      label="Select School "
                    />
                  )}
                />
              </Col>
              <Col md={3}></Col>
            </Row>
          )}

          {(reportType === "All" || reportType === "School Wise") && (
            <Row className="pt-4">
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button-30"
                  disabled={loading}
                  onClick={handleDownload}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" variant="primary" />
                  ) : (
                    <div>Download</div>
                  )}
                </button>
              </Col>
            </Row>
          )}
        </Row>
      </Container>

      <div className="mt-5 pt-5">
        <h6
          className="text-center py-3 fixed-bottom m-0 p-0 "
          style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}
        >
          2024 DESIGN & DEVELOPED BY | Shrabik Solutions{" "}
        </h6>
      </div>
    </Container>
    )
}

export default ReportPdSchool;




