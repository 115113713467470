import './App.css';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import KenyaLogin from './KenyaLogin';
import KenyaHomePage from './KenyaHomePage';
import KenyaOTPpage from './KenyaOTPpage';
import KenyaSchoolWise from './KenyaSchoolWise';
import KenyaCreateUser from './KenyaCreateUser';
import KenyaHeader from './KenyaHeader';
import KenyaPassChange from './KenyaPassChange';
import KenyaAdminDashboard from './KenyaAdminDashboard';
import KenyaForgotPass from './KenyaForgotPass';
import KenyaUserSummery from './KenyaUserSummery';
import KenyapCertificateStudent from './KenyapCertificateStudent';
import KenyapCertificateSchool from './KenyapCertificateSchool';
import KenyagCertificateStudent from './KenyagCertificateStudent';
import KenyagCertificateSchool from './KenyagCertificateSchool';
import KenyaGenerated_C_SchoolWise from './KenyaGenerated_C_SchoolWise';
import KenyaGenetated_C_All from './KenyaGenetated_C_All';
import KenyaCreateNewUser from './KenyaCreateNewUser';
import Navbar from './Navbar';
import ReportGdSchoolWise from './ReportGdSchoolWise';
import ReportGdAll from './ReportGdAll';
import ReportPdAll from './ReportPdAll';
import ReportPdSchool from './ReportPdSchool';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<KenyaHomePage />} />
        <Route path="/login" element={<KenyaLogin />} />
        <Route path="/otp" element={<KenyaOTPpage />} />
        <Route path="/downloadCertificate" element={<KenyaSchoolWise />} />
        <Route path="/createUser" element={<KenyaCreateUser />} />
        <Route path="/header" element={<KenyaHeader />} />
        <Route path="/passwordChange" element={<KenyaPassChange />} />
        <Route path="/adminDashboard" element={<KenyaAdminDashboard />} />
        <Route path="/forgotPassword" element={<KenyaForgotPass />} />
        <Route path="/newUserCreate" element={<KenyaCreateNewUser />} />

        <Route path="/gCertificateSchool" element={<KenyagCertificateSchool />} />
        <Route path="/gCertificateStudent" element={<KenyagCertificateStudent />} />
        <Route path="/pCertificateSchool" element={<KenyapCertificateSchool />} />
        <Route path="/pCertificateStudent" element={<KenyapCertificateStudent />} />
        <Route path="/generatedCertificateSchoolWise" element={<KenyaGenerated_C_SchoolWise />} />
        <Route path="/generatedCertificateAll" element={<KenyaGenetated_C_All />} />
        <Route path="/userSummery" element={<KenyaUserSummery />} />
        <Route path="/navbar" element={<Navbar />} />
        <Route path="/reportGdSchool" element={<ReportGdSchoolWise />} />
        <Route path="/reportGdAll" element={<ReportGdAll />} />
        <Route path="/reportPdAll" element={<ReportPdAll />} />
        <Route path="/reportPdSchool" element={<ReportPdSchool />} />

      </Routes>
    </>
  );
}

export default App;
