import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Assuming you're using React Router for navigation

function Navbar() {
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    // Get current path
    const path = window.location.pathname;
    setActiveTab(path);
  }, []);

  const handleItemClick = (path) => {
    setActiveTab(path);
  };

  return (
    <nav className="navbar navbar-expand-custom navbar-mainbg">
      <a className="navbar-brand navbar-logo" href="#">Navbar</a>
      <button className="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fas fa-bars text-white"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <div className="hori-selector"><div className="left"></div><div className="right"></div></div>
          <li className={`nav-item ${activeTab === '/' ? 'active' : ''}`}>
            <NavLink className="nav-link" to="/" onClick={() => handleItemClick('/')}>
              <i className="fas fa-tachometer-alt"></i>Dashboard
            </NavLink>
          </li>
          <li className={`nav-item ${activeTab === '/address-book' ? 'active' : ''}`}>
            <NavLink className="nav-link" to="/address-book" onClick={() => handleItemClick('/address-book')}>
              <i className="far fa-address-book"></i>Address Book
            </NavLink>
          </li>
          {/* Add more list items for other navigation links */}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
