import React, { useEffect, useState } from 'react';
import './KenyaLogin.css';
import { Col, Container, Form, Nav, Row, Spinner } from 'react-bootstrap';
import logo from './Assets/logo.png'
import laptop from './Assets/signup1.svg'
// import { Autocomplete, InputLabel, TextField } from '@mui/material';
import TextField from '@mui/material/TextField';

import { useNavigate } from 'react-router-dom';
import { UserCreateApi } from './Api';
import axios from 'axios';
import { toast } from 'react-toastify';
import KenyaHeader from './KenyaHeader';


const KenyaCreateUser = () => {

    const [firstName, setFirstName] = useState();
    const [email, setEmail] = useState();
    const [designation, setDesignation] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const tokenData = sessionStorage.getItem('token');
    const [token, setToken] = useState(null);


    const logoutFun = () => {
        sessionStorage.removeItem("user");
        sessionStorage.clear()
        navigate("/");
    }
    const handleCreateUser = () => {
        navigate('/newUserCreate');
      };

    const saveFun = (e) => {
        e.preventDefault();
        setLoading(true);

        const obj = {
            name: firstName,
            login_id: email,
            designation: designation
        }
        axios.post(UserCreateApi, obj,
            {
                headers: {
                    token: tokenData,
                },
            }
        )
            .then((res) => {
                if (res.data === 'SAVED') {
                    toast.success('Account created successfully! Please check your email for login credentials..!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setFirstName('');
                    setEmail('');
                    setDesignation('');
                    navigate('/newUserCreate');
                }else if (res.data === "ALREADYEXISTS") {
                    toast.error('Email already exists', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })}
                else if(res.data === "UNAUTHORIZED"){
                    navigate("/")
                }
                
            })

            .catch((err) => {
                toast.error('Failed to upload, please try again', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        let token = sessionStorage.getItem("token");
        let user = sessionStorage.getItem("user");

        if (token == null || user == null) {
            sessionStorage.clear()
            navigate("/")
        }
    }, [])


    return (
        <Container fluid>
            <Row >
               <KenyaHeader/>
            </Row>
            <Container>
                <Row className='mb-5 pb-5'>
                    <Col sm={12} md={5} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={laptop} className='w-100' />
                    </Col>
                    <Col md={7} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='login-container mb-4 mb-md-0'>
                            <div className='login-form shadow mb-5 mb-md-3' style={{ border: "3px solid #ffe500", borderRadius: "15px" }}>
                                <form
                                    onSubmit={saveFun}
                                >
                                    <div className='input'>

                                        <h1 className='pb-1'>Create User</h1>
                                        <Row className='pb-1'>

                                        <Row className='m-0 p-0'>
                                           <Col md={2}></Col>
                                            <Col sm={12} md={8} className='mt-3'>
                                                <TextField maxLength="50" id="standard-basic" label="Enter Name" variant="standard" style={{ width: "100%" }}
                                                    value={firstName}
                                                    required
                                                    onChange={(e) => {
                                                        const limitedValue = e.target.value.slice(0, 50);
                                                        setFirstName(limitedValue);
                                                    }} />
                                            </Col>
                                            <Col md={2}></Col>
                                           </Row>

                                            <Row className='m-0 p-0'>
                                           <Col md={2}></Col>
                                            <Col sm={12} md={8} className='mt-3'>
                                                <TextField maxLength="40" id="standard-basic" type="Email" label="Email id" variant="standard" style={{ width: "100%" }}
                                                    required
                                                    value={email}
                                                    onChange={(e) => {
                                                        const limitedValue = e.target.value.slice(0, 40);
                                                        setEmail(limitedValue);
                                                    }} />
                                            </Col>
                                            <Col md={2}></Col>
                                           </Row>

                                           <Row className='m-0 p-0'>
                                           <Col md={2}></Col>
                                           <Col maxLength="40" sm={12} md={8} className='mt-3'>
                                                <TextField id="standard-basic" label="Designation" variant="standard" style={{ width: "100%" }}
                                                    required
                                                    value={designation}
                                                    onChange={(e) => {
                                                        const limitedValue = e.target.value.slice(0, 40);
                                                        setDesignation(limitedValue);
                                                    }} />
                                            </Col>
                                            <Col md={2}></Col>
                                           </Row>

                                        </Row>

                                        <Row style={{display:"flex",justifyContent:"center"}}>
                                        <Col className='login-button mt-4 mb-3 mx-2'>
                                            <button type="submit" className='login-btn' >{loading ? <Spinner animation='border' size='sm' variant="primary" /> : <b>Create</b>}</button>
                                      
                                            <button onClick={handleCreateUser} style={{backgroundColor:"transparent"}} className='login-btn mx-2' >Cancel</button>
                                        </Col>
                                        </Row>

                                       

                                        <div className='text-center pt-2' style={{display:"flex",justifyContent:"center"}}>
                                            <p >
                                            Your login credentials will be sent to your registered email ID...!
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    )
}

export default KenyaCreateUser
