import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Modal, Row, Spinner, Table } from 'react-bootstrap';
import KenyaHeader from './KenyaHeader';
import axios from 'axios';
import { ChangeStatusOfUser, GetCreatedUserCSVFile, GgetCreatedUserList, UserSummeryApi, getExportedReportAPIURL } from './Api';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const KenyaCreateNewUser = () => {

  const [data, setData] = useState([]);
  const tokenData = sessionStorage.getItem('token');
  const user = JSON.parse(sessionStorage.getItem('user'));
  // const userId = user.userId;
  const roleId = user.role;
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [userId, setUserId] = useState()
  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleStatus = (e) => {
    setStatus(e.target.value)
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(GgetCreatedUserList,
      {
        headers: {
          token: tokenData,
        },
      })
      .then(res => {
        if (res.data === 'UNAUTHORIZED') {
          navigate('/');
      } else if (res.data !== null) {
        setData(res.data);
      }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const handleCreateUser = () => {
    navigate('/createUser');
  };

  const handleEdit = (item) => {
    setUserId(item.userId)
    setStatus(item.status)
    setShowModal(true);
  };


  const setUpdateData = () => {


    axios.post(`${ChangeStatusOfUser}?userId=${userId}&status=${status}`, null, {
      headers: {
        token: tokenData,
      }
    })
      .then((res) => {
        if (res.data == "UPDATED") {
          fetchData();
          toast.success('Status updated successfully!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (res.data === 'UNAUTHORIZED') {
          navigate('/');
        }
      })
      .catch((err) => console.log(err));
    setShowModal(false);
  };



  const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'User Name',
      field: 'name',
      sort: 'asc',
    },
    {
      label: 'Email Id',
      field: 'login_id',
      sort: 'asc',
    },
    {
      label: 'Role',
      field: 'role',
      sort: 'asc',
    },
    {
      label: 'Designation',
      field: 'designation',
      sort: 'asc',
    },
    {
      label: 'Status',
      field: 'status',
      sort: 'asc',
    },
    {
      label: 'Edit',
      field: 'action',
    },

  ];

  const customRows = data.map((item, index) => {

    const { id, name, designation, login_id, role, status } = item;

    let statusText, actionIcon, actionColor, roleText;
    switch (status) {
      case 0:
        statusText = 'Block';
        actionIcon = 'fa-triangle-exclamation';
        actionColor = '#f8882e';
        break;
      case 1:
        statusText = 'Active';
        actionIcon = 'fa-circle-check';
        actionColor = 'green';
        break;
      default:
        statusText = 'Unknown';
        actionIcon = '';
        actionColor = 'grey';
        break;
    }

    switch (role) {
      case 0:
        roleText = 'User';
        break;
      case 1:
        roleText = 'Admin';
        break;
      default:
        roleText = 'Unknown';
        break;
    }



    return {
      srNo: index + 1,
      name,
      designation,
      role: roleText,
      login_id,

      status: (
        <div style={{ color: actionColor }}>
          <i className={`fas ${actionIcon}`} style={{ marginRight: '8px' }}></i>
          {statusText}
        </div>
      ),

      action: (
        <div className='d-flex' style={{ justifyContent: "space-evenly" }}>
          <button
            id='edit'
            className='p-1'
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              color: "orange",
            }}
            onClick={() => handleEdit(item)}
          >
            <i class="fas fa-edit"></i>
          </button>
        </div>
      )
    };
  });

  const handlePrintCSV = () => {
    setLoading(true);
    axios.get(GetCreatedUserCSVFile, {
      headers: {
        token: sessionStorage.getItem('token'),
      },
    })
      .then((res) => {
        const pdfFileName = res.data;
        if (pdfFileName) {
          const pdfUrl = getExportedReportAPIURL + pdfFileName;
          window.open(pdfUrl, '_blank');
          setPdfUrl(pdfUrl);
        } else if (pdfFileName === 'UNAUTHORIZED') {
          navigate('/');
        } else {
          console.error('Invalid PDF URL');
        }
       
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        setLoading(false);
      });
  };


  return (
    <Container fluid>
      <Row>
        <KenyaHeader />
      </Row>
      <Container>

        <Row className='m-0 p-0 px-md-5 mt-md-3 mt-1 mb-3' >
          <Col style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={handleCreateUser} className='px-2 py-1 rounded' style={{ backgroundColor: "#2f2f41", color: "#ffe500", border: "none", fontSize: "18px" }}>
              + Create New User
            </Button>
            <button

              className=' button-30'

              disabled={loading}
              onClick={handlePrintCSV}
            >
              {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Export CSV</div>}
            </button>
          </Col>
        </Row>


        <Row className=' mb-5  shadow' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>

          <Row className='m-0 p-0 px-md-5 mt-md-4 mt-2'>
            <MDBDataTable
              className='mb-5 custom-datatable'
              striped
              bordered
              hover
              small
              data={{ columns, rows: customRows }}
              style={{ fontSize: "14px" }} />

          </Row>
        </Row>
      </Container>

      <Modal size='sm' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change User Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {modalContent()} */}
          <div className='row p-0 d-flex' >

            <div style={{ display: "flex" }}>
              <label><b>Status:</b></label>&nbsp;&nbsp;&nbsp;
              <select
                className="select-style p-0 ps-2"
                value={status}
                onChange={handleStatus}
                required
                style={{ borderRadius: "5px", height: "37px" }}
              >
                <option value="" disabled>Select Status</option>
                <option value="1">Active</option>
                <option value="0">Block</option>
              </select>
            </div>

          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => setUpdateData()}>
            Update
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <div>
        <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
      </div>
    </Container>
  )
}


export default KenyaCreateNewUser