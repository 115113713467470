

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, NavDropdown, Row, Col, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import logo from './Assets/logo.png';
import './KenyaLogin.css'

const KenyaHeader = () => {
  const [auth, setAuth] = useState(true);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const name = user.name;
  const tokenData = sessionStorage.getItem('token');

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      setToken(sessionStorage.getItem('token'));
    } else {
      setToken(null);
    }
  }, []);

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    let user = sessionStorage.getItem('user');

    if (token == null || user == null) {
      sessionStorage.clear();
      navigate('/');
    }
  }, []);

  const logoutFun = () => {
    sessionStorage.removeItem('user');
    sessionStorage.clear();
    navigate('/');
  };

  const handleDashboard = () => {
    navigate('/adminDashboard');
  };

  const handleCreateUser = () => {
    navigate('/newUserCreate');
  };

  const handleReportGeneratedAll = () => {
    navigate('/reportGdAll');
  };

  const handleReportGeneratedAllSchool = () => {
    navigate('/reportGdSchool');
  };

  const handleReportPendingAll = () => {
    navigate('/reportPdAll');
  };

  const handleReportPendingAllSchool = () => {
    navigate('/reportPdSchool');
  };

  const handleGenerateCertificateSchool = () => {
    navigate('/gCertificateSchool');
  };

  const handleGenerateCertificateStudent = () => {
    navigate('/gCertificateStudent');
  };

  const handlePendingCertificateSchool = () => {
    navigate('/pCertificateSchool');
  };

  // const handlePendingCertificateStudent = () => {
  //   navigate('/pCertificateStudent');
  // };

  const handleGeneratedSchoolWise = () => {
    navigate('/generatedCertificateSchoolWise');
  };
  const handleGeneratedAll= () => {
    navigate('/generatedCertificateAll');
  };

  const handleUserSummery = () => {
    navigate('/userSummery');
  };

  const handleDownloadCertificate = () => {
    navigate('/downloadCertificate');
  };

  const handlePasswordChange = () => {
    navigate('/passwordChange');
  };

  return (
    <Container fluid>
      <Row className='px-md-5 mt-3'>
        <Row>
          <Col sm={12} md={10} style={{ display: "flex" }} >
            <img src={logo} style={{ width: "75px", height: "65px" }} />
            <h2 className='ps-3 fs-1' style={{ display: "flex", color: "#000000", fontFamily: "Inder, var(--default-font-family)", lineHeight: "30px", alignItems: "center", justifyContent: "center", textShadow: "2px 4px 10px gray" }}>THE KENYA NATIONAL EXAMINATION COUNCIL </h2>
          </Col>
          <Col className='mt-2 mt-md-0' sm={12} md={2} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            {/* <Nav.Link className='px-2 py-1' style={{border:"1px solid #3d3d3d",borderRadius:"5px"}}><b><i class="fa-solid fa-user" title={user.name}></i>&nbsp;&nbsp;</b>{user.name}</Nav.Link> */}
            <Nav>
              <Dropdown>
                <Dropdown.Toggle className='px-2 py-1' style={{ border: "1px solid #3d3d3d", borderRadius: "5px", backgroundColor: "transparent", color: "blue" }}>
                  <b><i className="fa-solid fa-user" title={user.name}></i>&nbsp;&nbsp;</b>{user.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item className='navName' onClick={handlePasswordChange}>Change Password</Dropdown.Item>
                  <Dropdown.Item className='fs-md-5 navName' onClick={logoutFun}>Log-Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Col>
        </Row>
      </Row>

      <Row className='mb-4 mb-md-5 px-md-5 mt-3'>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Nav >

            <Nav.Link className=' navName' onClick={handleDashboard} >Dashboard</Nav.Link>


            {/* {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 && ( */}
            {/* <Nav.Link className=' navName' onClick={handleDownloadCertificate} >Download Certificate</Nav.Link> */}
            {/* )} */}

            
            <Nav.Link className=' navName' onClick={handlePendingCertificateSchool}>Pending Certificate</Nav.Link>
            {/* <NavDropdown
              id="nav-dropdown-dark-example"
              title={<span style={{ color: '#3d3d3d' }}>Pending Certificate</span>}
            >
              <NavDropdown.Item onClick={handlePendingCertificateSchool}>School Wise</NavDropdown.Item>
              <NavDropdown.Item onClick={handlePendingCertificateStudent}>Student Wise</NavDropdown.Item>
            </NavDropdown> */}

            <NavDropdown
              id="nav-dropdown-dark-example"
              title={<span style={{ color: '#3d3d3d' }}>Generate Certificate</span>}
            >
              <NavDropdown.Item onClick={handleGenerateCertificateSchool}>School Wise</NavDropdown.Item>
              <NavDropdown.Item onClick={handleGenerateCertificateStudent}>Student Wise</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link className=' navName' onClick={handleGeneratedAll}>Generated Certificate</Nav.Link>

            {/* <NavDropdown
              id="nav-dropdown-dark-example"
              title={<span style={{ color: '#3d3d3d' }}>Generated Certificate</span>}
            >
              <NavDropdown.Item onClick={handleGeneratedSchoolWise}>School Wise</NavDropdown.Item>
              <NavDropdown.Item onClick={handleGeneratedAll}>All</NavDropdown.Item>
            </NavDropdown> */}


            {/* <Nav.Link className=' navName' onClick={handleUserSummery} >Printed By</Nav.Link> */}

            {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 && (
       
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={<span style={{ color: '#3d3d3d' }}>Report</span>}
            >
 <NavDropdown.Item onClick={handleReportGeneratedAllSchool}>Generated Certificate</NavDropdown.Item>
 <NavDropdown.Item onClick={handleReportPendingAllSchool}>Pending Certificate</NavDropdown.Item>

             {/* <NavDropdown
              id="nav-dropdown-dark-example"
              title={<span style={{ color: '#3d3d3d' }}>Generated Certificate</span>}
            >
              <NavDropdown.Item onClick={handleReportGeneratedAll}>All</NavDropdown.Item>
              <NavDropdown.Item onClick={handleReportGeneratedAllSchool}>School Wise</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={<span style={{ color: '#3d3d3d' }}>Pending Certificate</span>}
            >
              <NavDropdown.Item onClick={handleReportPendingAll}>All</NavDropdown.Item>
              <NavDropdown.Item onClick={handleReportPendingAllSchool}>School Wise</NavDropdown.Item>
            </NavDropdown> */}
            </NavDropdown>
            )}

            {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 && (
              <Nav.Link className=' navName' onClick={handleCreateUser} >User Management</Nav.Link>
            )}
          </Nav>
        </Col>
      </Row>
    </Container>

  );
};

export default KenyaHeader;

