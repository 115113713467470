import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Nav, Row, Spinner, Table } from 'react-bootstrap';
import logo from './Assets/logo.png'
import { Autocomplete, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { useReactToPrint } from 'react-to-print';
import { SchoolFindAlltApi, SchoolListApi, SchoolPrintResultApi, StudentPrintResultApi, getExportedReportAPIURL } from './Api';
import axios from 'axios';
import { toast } from 'react-toastify';
import KenyaHeader from './KenyaHeader';

const KenyaSchoolWise = () => {

    const [school_master, setSchool_master] = useState([]);
    const [showSchoolWise, setShowSchoolWise] = useState(false);
    const [showStudentWise, setShowStudentWise] = useState(false);
    const tokenData = sessionStorage.getItem('token');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [regNo, setRegNo] = useState('');

    const logoutFun = () => {
        sessionStorage.removeItem("user");
        sessionStorage.clear()
        navigate("/");
    }
    useEffect(() => {
        let token = sessionStorage.getItem("token");
        let user = sessionStorage.getItem("user");

        if (token == null || user == null) {
            sessionStorage.clear()
            navigate("/")
        }
    }, [])

    // =================== School get api ===================
    const Schooldata = (e) => {
        axios
            .get(SchoolListApi,
                {
                    headers: {
                        token: tokenData,
                    },
                })
            .then((res) => {
                if (res.data !== null) {
                    setSchool_master(res.data);
                }else if (res.data === 'UNAUTHORIZED') {
                    navigate('/')
                }
            })
            .catch((err) => {
                // alert("Failed to upload, try again");
                toast.error("Failed to upload, try again", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };


    useEffect(() => {
        Schooldata();
    }, []);

    // ---------------print-----
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: 'Download Certificate',
    //     // onAfterPrint: () => alert('Print Success')
    // });

    // const componentRef = useRef();
    // const pdfOptions = {
    //     margin: 10,
    //     filename: 'Payment_Recipt.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //     pagebreak: { before: '.page-break' },
    // };

    const handlePrintClick = () => {
        setLoading(true);
        // Check if school name is selected
        if (!selectedSchool) {
            toast.error("Please select a school", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }

        // Make API call with the selected school name
        axios.get(`${SchoolPrintResultApi}?schoolName=${encodeURIComponent(selectedSchool)}`, {
            headers: {
                token: tokenData,
            },
        })
            .then((res) => {
                const pdfFileName = res.data
                if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + `${pdfFileName}`

                    window.open(pdfUrl, '_blank')

                    // If you still want to store the URL in the state for reference
                    setPdfUrl(pdfUrl)
                }
                else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/')
                } else {
                    console.error('Invalid PDF URL')
                }
            })
            .catch((err) => {
                toast.error("Failed to fetch data, try again", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .finally(() => {
                // Set loading to false in the finally block
                setLoading(false);
                // setSchool_master([]);
            });

        // You can keep the existing code for generating and saving PDF
        // const contentToPrint = document.getElementById('content-to-print');
        // html2pdf().from(contentToPrint).set(pdfOptions).outputPdf((pdf) => {
        //     saveAs(pdf, 'Payment_Recipt.pdf');
        // });

    };

    // ============== student wise print api =============
    const handleStudentPrint = () => {
        setLoading(true);

        // Check if registration number is entered
        if (!regNo) {
            toast.error("Please enter a registration number", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false);  // Set loading to false here as well
            return;
        }

        axios.get(`${StudentPrintResultApi}?regNo=${encodeURIComponent(regNo)}`, {
            headers: {
                token: tokenData,
            },
        })
            .then((res) => {
                const pdfFileName = res.data;
                if(pdfFileName=== "NOTFOUND"){
                    toast.warn("Data not found", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/')
                }
               else if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + `${pdfFileName}`;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } else {
                    console.error('Invalid PDF URL');
                }
            })
            .catch((err) => {
                toast.error("Failed to fetch data, try again", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .finally(() => {
                // Set loading to false in the finally block
                setLoading(false);
                setRegNo(''); // Reset regNo to clear the input field
            });
    };


    return (
        <Container fluid>
            <Row>
                <KenyaHeader />
            </Row>
            <Container>

                <Row className=' pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                    <Row>
                        <Col className='ps-4'>
                            <h2>Download Certificate</h2>
                        </Col>
                    </Row>

                    <Row className='ps-md-5 ps-1 pt-4 '>
                        <Col className='ps-5'>
                            <button
                                className='py-1 px-3 fs-5 shadow me-2 mb-3'
                                style={{ color: "#3d3d3d", backgroundColor: "#ffe500", border: "none", borderRadius: "15px" }}
                                onClick={() => {
                                    setShowSchoolWise(true);
                                    setShowStudentWise(false);
                                }}
                            >
                                <i className="fa-solid fa-school"></i> &nbsp;School Wise
                            </button>
                            <button
                                className='py-1 px-3 fs-5 shadow mx-2'
                                style={{ color: "#3d3d3d", backgroundColor: "#ffe500", border: "none", borderRadius: "15px" }}
                                onClick={() => {
                                    setShowStudentWise(true);
                                    setShowSchoolWise(false);
                                }}
                            >
                                <i className="fa-solid fa-user"></i> &nbsp;Student Wise
                            </button>
                        </Col>
                    </Row>

                    {/* =============== School Wise ========= */}

                    {showSchoolWise && (
                        <>
                        <Row className='px-md-5 m-0 px-3 pt-3' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Col sm={12} md={6} className='mt-3 mb-1 px-md-5 '>
                                    <Form.Label className='mb-0'><h6>School Name :</h6></Form.Label>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size="small"
                                        options={school_master}
                                        getOptionLabel={(option) => option.schoolName}
                                        value={school_master.find((item) => item.schoolName === selectedSchool) || null}
                                        onChange={(e, newValue) => setSelectedSchool(newValue ? newValue.schoolName : '')}
                                        renderInput={(params) => <TextField {...params} style={{ width: '100%' }} label="Select School " />}
                                    />

                                </Col>
                                <Col sm={12} md={2} style={{ display: "flex", justifyContent: "center" }}>
                                    <button
                                        onClick={handlePrintClick}
                                        className='py-1 px-4 fs-5 mt-5'
                                        style={{ color: "#3d3d3d", backgroundColor: "transparent", border: "2px solid #3d3d3d", borderRadius: "15px" }}
                                        disabled={loading}
                                    >
                                        {loading ? <Spinner animation='border' size='sm' variant="primary"/> : <div><i className="fa-solid fa-print"></i> &nbsp;Print</div>}
                                    </button>
                                </Col>
                            </Row>

                        </>
                    )}

                    {/* ================ Student Wise ================= */}

                    {showStudentWise && (
                    <>
                        <Row className='px-md-5 m-0 px-3 pt-3' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Col sm={12} md={6} className='mt-3 mb-1 px-md-5 '>
                                <Form.Label className='mt-0'><h6 >Reg No. :</h6></Form.Label>
                                <Form.Control
                                    id="regNoInput"
                                    aria-label="Default select example"
                                    placeholder='Enter Reg No.'
                                    required
                                    maxLength={15}
                                    style={{ width: '100%' }}
                                    value={regNo}  // Controlled component
                                    onChange={(e) => setRegNo(e.target.value)}  // Update state on input change
                                />
                            </Col>
                            <Col sm={12} md={2} style={{ display: "flex", justifyContent: "center" }}>
                                <button
                                    onClick={handleStudentPrint}
                                    className='py-1 px-4 fs-5 mt-5'
                                    style={{ color: "#3d3d3d", backgroundColor: "transparent", border: "2px solid #3d3d3d", borderRadius: "15px" }}
                                    disabled={loading}
                                >
                                    {loading ? <Spinner animation='border' size='sm' variant="primary"/> : <div><i className="fa-solid fa-print"></i> &nbsp;Print</div>}
                                </button>
                            </Col>
                        </Row>
                    </>
                )}

                </Row>

            </Container>

            <div className='mt-5 pt-5'>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    )
}

export default KenyaSchoolWise
