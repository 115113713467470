import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import KenyaHeader from './KenyaHeader';
import axios from 'axios'; 
import { GReportGetPendingCountStudentWise, GgenerateCSVSchoolAndStudentCount, ReportgenerateCSVPendingCountStudentWise, UserSummeryApi, getExportedReportAPIURL } from './Api';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';

const ReportPdAll = () => {
    const [data, setData] = useState([]); 
    const tokenData = sessionStorage.getItem('token');
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user.userId;
    const roleId = user.role;
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get(GReportGetPendingCountStudentWise ,
            {
                headers: {
                    token: tokenData,
                },
            })
            .then(response => {
               console.log("api...",response.data);
                if (response.data === 'UNAUTHORIZED') {
                    navigate('/');
                  } else if (response.data !== null) {
                    setData(response.data);
                  }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handlePrintCSV = () => {
        setLoading(true);
        axios.get(ReportgenerateCSVPendingCountStudentWise, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
            .then((res) => {
                const pdfFileName = res.data;
                if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                } else {
                    console.error('Invalid PDF URL');
                }
                setLoading(false);
            })
            .catch((error) => {
                // Handle error
                console.error('Error fetching CSV data:', error);
                setLoading(false);
            });
    };

    const columns = [
      {
        label: 'Sr.No',
        field: 'srNo',
        sort: 'asc',
    },
      {
        label: 'Reg No',
        field: 'regNo',
        sort: 'asc',
    },
    {
      label: 'Student Name',
      field: 'studentName',
      sort: 'asc',
  },
    
    {
      label: 'School Name',
      field: 'schoolName',
      sort: 'asc',
  },
    
   
      ];
      
      const customRows = data.map((item, index) => {
    
        const { id,userName,schoolName,studentName, regNo } = item;
   
      
        return {
            srNo: index + 1,
            schoolName,
            studentName,
            regNo,
          };
        });
    


    return (
        <Container fluid>
        <Row>
            <KenyaHeader />
            
        </Row>
        <Container>
            <Row className='pb-5 mb-5 py-4 shadow' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                
                <Row className='m-0 p-0 px-md-5  mt-2'>
                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                        <h2 className='ps-1' style={{borderLeft:"3px solid blue"}}>Report Pending Certificate All</h2>
                            <button

                                className=' button-30'

                                disabled={loading}
                                onClick={handlePrintCSV}
                            >
                                {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Export CSV</div>}
                            </button>
                        </Col>
                    </Row>

                <Row className='m-0 p-0 px-md-5 mt-md-4 mt-2'>
                <MDBDataTable
                    className='mb-5 custom-datatable'
                    striped
                    bordered
                    hover
                    small
                    data={{ columns, rows: customRows }}
                    style={{ textAlign: "center", fontSize: "14px" ,}}/>

                </Row>
            </Row>
        </Container>

        <div>
            <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
        </div>
    </Container>
    )
}

export default ReportPdAll

