
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Spinner, Table, Form, Button, Card } from 'react-bootstrap';
import KenyaHeader from './KenyaHeader';
import axios from 'axios';
import { DashboardData, GenerateCSVPrintedCount, GetDashboardDataa, OnclickgetStudentDataBySchool, getExportedReportAPIURL } from './Api';
import { useNavigate } from 'react-router-dom';
import cloudImg from './Assets/cloud.webp';
import { MDBDataTable } from 'mdbreact';

const KenyaAdminDashboard = () => {
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });

        return () => {
            window.removeEventListener('popstate', function (event) {
                window.history.pushState(null, document.title, window.location.href);
            });
        };
    }, []);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // State for filtered data
    const [searchKeyword, setSearchKeyword] = useState(''); // State for search keyword
    const tokenData = sessionStorage.getItem('token');
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user.userId;
    const roleId = user.role;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedSchoolData, setSelectedSchoolData] = useState(null); // State for selected school data
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [itemsPerPage] = useState(10); // State for items per page
    const [dashboardData, setDashboardData] = useState(null);

    useEffect(() => {
        fetchData();
        fetchData1();
    }, []);

  const fetchData1 = () => {
    axios.get(GetDashboardDataa, {
        headers: {
            token: tokenData,
        },
    })
    .then(res => {
        if (res.data === 'UNAUTHORIZED') {
            navigate('/');
        } else if (res.data !== null) {
            setDashboardData(res.data);
        }
    })
    .catch(error => {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
    });
};
  

    useEffect(() => {
        const filtered = data.filter(item =>
            item.schoolName.toLowerCase().includes(searchKeyword.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchKeyword, data]);

    const fetchData = () => {
        axios.get(`${DashboardData}?roleId=${roleId}&userId=${userId}`, {
            headers: {
                token: tokenData,
            },
        })
        .then(res => {
            if (res.data === 'UNAUTHORIZED') {
                navigate('/');
            } else if (res.data !== null) {
                setData(res.data);
            }
        })
        .catch(error => {
            setError('Error fetching data');
        });
    };

    const handlePrintCSV = () => {
        setLoading(true);
        axios.get(GenerateCSVPrintedCount, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
        .then((res) => {
            const pdfFileName = res.data;
            if (pdfFileName) {
                const pdfUrl = getExportedReportAPIURL + pdfFileName;
                window.open(pdfUrl, '_blank');
            } else if (pdfFileName === 'UNAUTHORIZED') {
                navigate('/');
            } else {
                console.error('Invalid PDF URL');
            }
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error fetching CSV data:', error);
            setLoading(false);
        });
    };

    const handleRowClick = (rowData) => {
        setLoading(true);
        axios.get(`${OnclickgetStudentDataBySchool}?schoolName=${rowData.schoolName}`, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
        .then((res) => {
            console.log("modal console...",res.data);
            setSelectedSchoolData(res.data);
            setShowModal(true);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error fetching student data by school:', error);
            setError('Error fetching student data');
            setLoading(false);
        });
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const columns = [
        {
            label: 'Sr.no',
            field: 'srNo',
            sort: 'asc',
        },
        {
            label: 'School Name',
            field: 'schoolName',
            sort: 'asc',
        },
        {
            label: 'Total Student Count',
            field: 'totalCount',
            sort: 'asc',
        },
        {
            label: 'Generated Count',
            field: 'generatedCount',
            sort: 'asc',
        },
        {
            label: 'Pending Count',
            field: 'pendingCount',
            sort: 'asc',
        },

    ];

    const customRows = data.map((item, index) => {
        const { id, schoolName, totalCount, generatedCount, pendingCount } = item;
    
        return {
            srNo: index + 1,
            schoolName,
            totalCount,
            generatedCount,
            pendingCount
        };
    });

    return (
        <Container fluid>
            <Row>
                <KenyaHeader />
            </Row>
            <Container>
                <Row className=' mb-5 py-4 shadow' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                    <Row className='m-0 p-0'>
                        <Col className='text-center'>
                            <h1>Dashboard</h1>
                        </Col>
                    </Row>
                    <Row className='m-0 p-0 px-md-5 '>
                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 className='ps-1' style={{borderLeft:"3px solid blue"}}>Generate Certificate Data</h3>
                            <button
                                className=' button-30'
                                disabled={loading}
                                onClick={handlePrintCSV}
                            >
                                {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Export CSV</div>}
                            </button>
                        </Col>
                    </Row>

                  

    <Row md={3} className='g-4 m-0 p-0 mt-2 px-md-5'>
      <Col>
        <Card className='cloudImg'>
          <Card.Body className='text-center'>
          <Card.Text><h3>{dashboardData && dashboardData.totalCount}</h3></Card.Text>
            <Card.Title>Total student count</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className='cloudImg1'>
          <Card.Body className='text-center'>
          <Card.Text><h3>{dashboardData && dashboardData.totalGeneratedCount}</h3></Card.Text>
            <Card.Title >Total generated count</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className='cloudImg2'>
          <Card.Body className='text-center'>
          <Card.Text><h3>{dashboardData && dashboardData.totalPendingCount}</h3></Card.Text>
            <Card.Title>Total pending count</Card.Title>
          </Card.Body>
        </Card>
      </Col>
      
    </Row>

    <Row className='m-0 p-0 px-md-5 mt-md-4 mt-2'>
                <MDBDataTable
                    className='mb-5 custom-datatable'
                    striped
                    bordered
                    hover
                    small
                    data={{ columns, rows: customRows }}
                    style={{ textAlign: "center", fontSize: "14px" ,}}/>

                </Row>

                    {/* <Row className='m-0 p-0 px-md-5 mt-md-4 mt-2'>
                        <Row className='m-0 p-0'>

                        <Col  style={{display:"flex",justifyContent:"end"}} className='mb-2 mt-2 mt-md-1' >
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Search by school name"
                                value={searchKeyword}
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </Form.Group>
                        </Col>
                        </Row>

                        <div style={{ overflowY: 'auto' }}>
                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Sr.No</th>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>School Name</th>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Total Student Count</th>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Generated Count</th>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Pending Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((rowData, index) => (
                                    <tr key={index}
                                    //  onClick={() => handleRowClick(rowData)}
                                     >
                                        <td className='text-center'>{indexOfFirstItem + index + 1}</td>
                                        <td className='ps-md-5 ps-2'>{rowData.schoolName}</td>
                                        <td className='text-center'>{rowData.totalCount}</td>
                                        <td className='text-center'>{rowData.generatedCount}</td>
                                        <td className='text-center'>{rowData.pendingCount}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        </div>
                        </div>
                        <nav>
                            <ul className='pagination'>
                                {[...Array(Math.ceil(filteredData.length / itemsPerPage)).keys()].map(number => (
                                    <li key={number} className='page-item'>
                                        <button onClick={() => paginate(number + 1)} className='page-link'>
                                            {number + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </Row> */}
                </Row>
            </Container>
            <div>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
            {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Student Data for {selectedSchoolData && selectedSchoolData.schoolName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : error ? (
                        <div>{error}</div>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Student Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedSchoolData &&
                                    selectedSchoolData.students.map((student, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{student.schoolName}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
            </Modal> */}
        </Container>
    )
}

export default KenyaAdminDashboard;
