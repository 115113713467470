import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import KenyaHeader from './KenyaHeader'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { GenerateCSVGeneratedCertificateAll, GenerateCSVGeneratedCertificateSchoolWise, GeneratedSchoolListApi, GeneratedSchoolTableApi, SchoolListApi, getExportedReportAPIURL } from './Api';
import { toast } from 'react-toastify';

const KenyaGenerated_C_SchoolWise = () => {

  const [school_master, setSchool_master] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [regNo, setRegNo] = useState('');
  const [certNo, setCertNo] = useState(0)
  const [preFix, setPreFix] = useState('');
  const user = JSON.parse(sessionStorage.getItem('user'));
  const userId = user.userId;
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch school data
    axios
      .get(GeneratedSchoolListApi, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data === 'UNAUTHORIZED') {
          navigate('/');
        } else if (res.data !== null) {
          setSchool_master(res.data);
        }
      })
      .catch((err) => {
        toast.error('Failed to fetch schools, try again', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  }, []);

  useEffect(() => {
    if (selectedSchool) {
      // Fetch data for the selected school
      axios
        .get(`${GeneratedSchoolTableApi}?schoolName=${selectedSchool}`, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then((res) => {
         
          if (res.data === 'UNAUTHORIZED') {
            navigate('/');
          } else if (res.data !== null) {
             setData(res.data); 
          }// Assuming the response data is an array of objects
        })
        .catch((err) => {
          toast.error('Failed to fetch data, try again', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }
  }, [selectedSchool]);

  const handlePrintCSV = () => {
    setLoading(true);
    axios.get(`${GenerateCSVGeneratedCertificateSchoolWise}?schoolName=${selectedSchool}`, {
      headers: {
        token: sessionStorage.getItem('token'),
      },
    })
      .then((res) => {
        const pdfFileName = res.data;
        if (pdfFileName) {
          const pdfUrl = getExportedReportAPIURL + pdfFileName;
          window.open(pdfUrl, '_blank');
          setPdfUrl(pdfUrl);
        } else if (pdfFileName === 'UNAUTHORIZED') {
          navigate('/');
        } else {
          console.error('Invalid PDF URL');
        }
       
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching CSV data:', error);
        setLoading(false);
      });
  };



  return (
    <Container fluid>
      <Row>
        <KenyaHeader />
      </Row>
      <Container>

        <Row className=' pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
          <Row style={{ display: "flex", justifyContent: "start" }}>
            <Col className='ps-4'>
              <h2>Generated Certificate School Wise</h2>
            </Col>

          </Row>

          <Row className=' m-0 pt-4 px-md-5' style={{ display: "flex", justifyContent:"space-between" }}>
            

            <Col >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                options={school_master}
                getOptionLabel={(option) => option.schoolName}
                value={school_master.find((item) => item.schoolName === selectedSchool) || null}
                onChange={(e, newValue) => setSelectedSchool(newValue ? newValue.schoolName : '')}
                renderInput={(params) => <TextField {...params} style={{ width: '100%' }} label="Select School " />}
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <button

                className=' button-30'

                disabled={loading}
                onClick={handlePrintCSV}
              >
                {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Export CSV</div>}
              </button>
            </Col>
          </Row>


          <Row className='m-0 p-0 px-md-5 mt-md-2 mt-2'>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <Table striped bordered hover size="sm" className='shadow mt-2' style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Sr.No</th>
                      <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Student Name</th>
                      <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Certificate No</th>
                      <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Generated Date</th>
                      <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Generated By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr className='border'>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{item.studentName}</td>
                        <td style={{ textAlign: "center" }}>{item.certificateNo}</td>
                        <td style={{ textAlign: "center" }}>{item.printDate}</td>
                        <td style={{ textAlign: "center" }}>{item.userName}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Row>


        </Row>

      </Container>
      <div className='mt-5 pt-5'>
        <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
      </div>
    </Container>
  )
}
export default KenyaGenerated_C_SchoolWise