// export const BaseIp = 'http://192.168.29.102:8080/kenya-result/';     // offline
// export const getExportedReportAPIURL =' http://192.168.29.102:8080/' + "output/";


// ============================== Online ===========================
export const BaseIp = 'http://157.66.191.17:9080/kenya-result/';   // online
export const getExportedReportAPIURL ='http://157.66.191.17:9080/' + "output/";  //  online


export const LoginApi = BaseIp + "kenya/user/signIn";
export const OtpApi = BaseIp + "kenya/user/otpVerification";
export const VerificationOtpApi = BaseIp + "kenya/user/resendOtp";
export const UserCreateApi = BaseIp + "kenya/user/createUser";
export const SchoolListApi = BaseIp + "kenya/exam/getSchoolList";
export const SchoolPrintResultApi = BaseIp + "kenya/exam/printResultBySchool";
export const StudentPrintResultApi = BaseIp + "kenya/exam/printResultByExamNo";
export const ChangePassApi = BaseIp + "kenya/user/changePassword"; 
export const SendOtpForResetPassword = BaseIp + "kenya/user/sendOtpForResetPassword";
export const OtpVerificationForResetPassword = BaseIp + "kenya/user/otpVerificationForResetPassword";
export const ResetPassword = BaseIp + "kenya/user/resetPassword";
export const PendingCountStudentWise = BaseIp + "kenya/printData/getPendingCountStudentWise";
export const PendingCountSchoolWise = BaseIp + "kenya/printData/getPendingCountSchoolWise";
export const GeneratedCertificateAll = BaseIp + "kenya/printData/getGeneratedCertificateAll";
export const GeneratedSchoolListApi = BaseIp + "kenya/printData/getGeneratedCertificateSchoolList";
export const GeneratedSchoolTableApi = BaseIp + "kenya/printData/getGeneratedCertificateSchoolWise";
export const DashboardData = BaseIp + "kenya/printData/getPrintedCount";
export const UserSummeryApi = BaseIp + "kenya/printData/getSchoolAndStudentCount";
export const GetLastCertificateNo = BaseIp + "kenya/printData/getLastCertificateNo";
export const GenerateCSVPendingCountStudentWise = BaseIp + "kenya/printData/generateCSVPendingCountStudentWise";
export const GenerateCSVPendingCountSchoolWise = BaseIp + "kenya/printData/generateCSVPendingCountSchoolWise";  // Pending Certificate CSV
export const GenerateCSVGeneratedCertificateSchoolWise = BaseIp + "kenya/printData/generateCSVGeneratedCertificateSchoolWise";
export const GenerateCSVGeneratedCertificateAll = BaseIp + "kenya/printData/generateCSVGeneratedCertificateAll";  // Generated Certificate CSV
export const GgetCreatedUserList = BaseIp + "kenya/user/getCreatedUserList";
export const ChangeStatusOfUser = BaseIp + "kenya/user/changeStatusOfUser";
export const GetCreatedUserCSVFile = BaseIp + "kenya/user/getCreatedUserCSVFile"; // Manage User CSV
export const GenerateCSVPrintedCount = BaseIp + "kenya/printData/generateCSVPrintedCount"; // Dashboard CSV
export const GgenerateCSVSchoolAndStudentCount = BaseIp + "kenya/printData/generateCSVSchoolAndStudentCount"; // Printed By CSV
export const PrintResultBySchoolPendingCert = BaseIp + "kenya/exam/printResultBySchoolPendingCert"; 
export const OnclickgetStudentDataBySchool = BaseIp + "kenya/printData/getStudentDataBySchool"; 
export const PrintGeneratedResultBySchool = BaseIp + "kenya/exam/printGeneratedResultBySchool"; 
export const GetDashboardDataa = BaseIp + "kenya/printData/getDashboardData"; 
export const GReportGetPendingCountStudentWise = BaseIp + "kenya/printData/getPendingCountStudentWise"; 
export const ReportgenerateCSVPendingCountStudentWise = BaseIp + "kenya/printData/generateCSVPendingCountStudentWise"; 
export const ReportgenerateCSVPendingListSchoolWise = BaseIp + "kenya/printData/generateCSVPendingListSchoolWise"; 
export const ReportgetPendingListSchoolWise = BaseIp + "kenya/printData/getPendingListSchoolWise"; 
export const ReportgetGeneratedCertificateAll = BaseIp + "kenya/printData/getGeneratedCertificateAll"; 
export const ReportgetGeneratedCertificateSchoolWise = BaseIp + "kenya/printData/getGeneratedCertificateSchoolWise"; 
export const ReportgenerateCSVGeneratedCertificateSchoolWise = BaseIp + "kenya/printData/generateCSVGeneratedCertificateSchoolWise"; 
export const GgenerateCSVGeneratedCertificateAll = BaseIp + "kenya/printData/generateCSVGeneratedCertificateSchoolListCount"; 















// setTimeout(() => {
//     setLoading(false);
//   }, 4000); // Adjust the delay time as needed
// });






// export const BaseIp = 'http://103.104.74.225:9080/kenya-result/';   // online
// export const getExportedReportAPIURL ='http://103.104.74.225:9080/' + "output/";  //  online