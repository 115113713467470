import React, { useEffect, useState } from 'react';
import './KenyaLogin.css';
import { Col, Container, Nav, Navbar, Row, Spinner } from 'react-bootstrap';
import logo from './Assets/logo.png';
import otpImage from './Assets/otpImage.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OtpApi, VerificationOtpApi } from './Api';
import { useNavigate } from 'react-router-dom';
import KenyaHeader from './KenyaHeader';

const KenyaOTPpage = () => {
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(180); // Timer in seconds (3 minutes)
    const navigate = useNavigate();
    const user = JSON.parse(sessionStorage.getItem('user'));
    const email = user.login_id;
    const [loading, setLoading] = useState(false)
    const [isloading, setisLoading] = useState(false)
    const tokenData = sessionStorage.getItem('token');
    const [token, setToken] = useState(null);

    const logoutFun = () => {
        sessionStorage.removeItem("user");
        sessionStorage.clear()
        navigate("/");    
      }

//   ============================= stop Pre page ================
useEffect(() => {
    // Disable browser back button
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function(event) {
        window.history.pushState(null, document.title, window.location.href);
    });

    return () => {
        // Remove the event listener when the component unmounts
        window.removeEventListener('popstate', function(event) {
            window.history.pushState(null, document.title, window.location.href);
        });
    };
}, []);

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            setToken(sessionStorage.getItem('token'));
        } else {
            setToken(null);
        }
    }, []);
    

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    const saveFun = (e) => {
        e.preventDefault();
        setLoading(true);
    
        axios
            .post(
                `${OtpApi}?otp=${otp}&loginId=${email}`,
                {
                    headers: {
                        token: tokenData,
                    },
                }
            )
            .then((res) => {
                if (res.data === 'NOTFOUND') {
                    toast.warn('User not found or Invalid Otp...!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setOtp('');
                    navigate('/otp');
                } else if (res.data === 'VERIFIED') {
    
                    // Store user details in session storage
                    sessionStorage.setItem('user', JSON.stringify(user));
    
                    if (user.role === 1) {
                        // Admin user, navigate to "/createUser"
                        navigate('/adminDashboard');
                    } else {
                        // Other users, navigate to "/downloadCertificate"
                        navigate('/adminDashboard');
                    }
    
                    setOtp('');
                } else if (res.data === 'INVALID') {
                    toast.error('Otp Expired...!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setOtp('');
                    navigate('/otp');
                }
                else if(res.data === "UNAUTHORIZED"){
                    navigate("/")
                }
            })
            .catch((err) => {
                toast.error('Failed to upload, please try again', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };


    // ==================== RESEND otp ========================
    const resendOtp = () => {
        setisLoading(true);
        axios
            .post(
                `${VerificationOtpApi}?loginId=${email}`,
                {
                    headers: {
                        token: tokenData,
                    },
                }
            )
            .then((res) => {

                // Handle the response as needed
                // For example, show a success message or handle errors
                if (res.data === 'SEND') {
                    toast.success('OTP Resent successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    // Reset the timer
                    setTimer(180);
                }
                else if(res.data === "UNAUTHORIZED"){
                    navigate("/")
                }
                else {
                    // Handle errors or other responses
                    toast.error('Failed to resend OTP, please try again', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                
            })
            .catch((error) => {
                alert(`Server Error`);
            })
            .finally(() => {
                setisLoading(false); // Set isloading to false after the request is completed
            });
    };

    useEffect(() => {
        let token = sessionStorage.getItem("token");
        let user = sessionStorage.getItem("user");

        if (token == null || user == null) {
            sessionStorage.clear()
            navigate("/")
        }
    }, [])

    return (
        <Container fluid>
            <Row>
                 <Navbar className="mb-md-5 mb-3 px-md-4" expand="lg" style={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: 'none',display:"flex",justifyContent:"center",alignItems:"center" }}>
      <Container fluid>
        <Navbar.Brand  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <img src={logo} style={{ width: '75px', height: '65px' }} alt="Logo" />
          <span className="ps-3 fs-1 m-0" style={{ color: '#000000', fontFamily: 'Inder, var(--default-font-family)', lineHeight: '30px', alignItems: 'center', justifyContent: 'center', textShadow: '2px 4px 10px gray' }}>
            THE KENYA NATIONAL EXAMINATION COUNCIL
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ms-auto" >
            {/* {auth && ( */}
              {/* <> */}
            
                
                <Nav.Link className='fs-5' onClick={logoutFun} style={{color:"##000000"}}>Log-Out</Nav.Link>
                
              {/* </> */}
            {/* )} */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
            </Row>
            <Container>
                <Row className='px-md-5'>
                    <Col className=' p-md-5 p-0' sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={otpImage} className='w-100' />
                    </Col>
                    <Col md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='login-container mb-4 mb-md-0'>
                            <div className='login-form shadow mb-5 mb-md-3' style={{ border: "3px solid #ffe500", borderRadius: "15px" }}>
                                <form
                                    onSubmit={saveFun}
                                >
                                    <div className='input'>

                                        <h1 className='m-0 p-0 mb-3'>Verification</h1>

                                        <input
                                            id='input'
                                            placeholder='Enter OTP'
                                            maxLength="6"
                                            inputMode="numeric"
                                            title='Please enter 6 digit OTP.'
                                            value={otp}
                                            onChange={(e) => {
                                                const numericValue = e.target.value.replace(/\D/g, "");
                                                setOtp(numericValue);
                                            }}
                                            required
                                        />

                                        <div className='pt-2' style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Nav.Link style={{ color: 'blue' }} onClick={resendOtp}>
                                                <small className='forgot' style={{ color: 'gray', display: 'flex' }}>
                                                    Didn't receive the code?
                                                    <div style={{ color: 'blue' }} disabled={isloading}>{isloading ? <Spinner animation='border' variant="primary" /> : <p>&nbsp;Resend</p>}</div>
                                                </small>
                                            </Nav.Link>
                                        </div>

                                        <div className='login-button mt-2'>
                                            <button type="submit" className='login-btn shadow' disabled={loading}>
                                                {loading ? <Spinner animation='border' variant="primary" /> : <b>CONFIRM</b>}</button>
                                        </div>
                                    </div>
                                </form>
                                <div>
                                    <h6 className='text-center py-3 pt-4 m-0 p-0' style={{ color: "#3d3d3d" }}>
                                        {timer > 0 ? `Time remaining: ${Math.floor(timer / 60)}:${(timer % 60).toString().padStart(2, '0')}` : 'OTP Expired'}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    )
}

export default KenyaOTPpage