import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner, Modal, Button } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import KenyaHeader from './KenyaHeader';
import { GetLastCertificateNo, SchoolListApi, SchoolPrintResultApi, getExportedReportAPIURL } from './Api';
import { useNavigate } from 'react-router-dom';

const KenyagCertificateSchool = () => {
    const [school_master, setSchool_master] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [regNo, setRegNo] = useState('');
    const [certNo, setCertNo] = useState(0)
    const [preFix, setPreFix] = useState('');
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user.userId;
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [certificateType, setCertificateType] = useState('');
    const [printPrevious, setPrintPrevious] = useState(0);
    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);
    const [lastCertNo, setLastCertNo] = useState('');

    const [isChecked, setIsChecked] = useState(false); // State to track checkbox status
    const [selectedValue, setSelectedValue] = useState(0); // State to store selected value

    const handleCheckboxChange = () => {
        // Toggle checkbox status
        setIsChecked(!isChecked);
        // Set selected value based on checkbox status
        setSelectedValue(isChecked ? 0 : 1);
    };

    const Schooldata = () => {
        axios
            .get(SchoolListApi, {
                headers: {
                    token: sessionStorage.getItem('token'),
                },
            })
            .then((res) => {
                if (res.data === 'UNAUTHORIZED') {
                    navigate('/');
                } else if (res.data !== null) {
                    setSchool_master(res.data);
                }
            })
            .catch((err) => {
                toast.error('Failed to fetch schools, try again', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    };

    useEffect(() => {
        Schooldata();
    }, []);

    const handlePrintClick = () => {
        setLoading(true);

        // Check if school name is selected
        if (!selectedSchool) {
            toast.error('Please select a school', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false);
            return;
        }

        console.log("api console....",`${SchoolPrintResultApi}?userId=${userId}&prefix=${preFix}&certNo=${certNo}&printPrevious=${printPrevious}&schoolName=${encodeURIComponent(selectedSchool)}&withBg=${selectedValue}`)
        axios.get(
                `${SchoolPrintResultApi}?userId=${userId}&prefix=${preFix}&certNo=${certNo}&printPrevious=${printPrevious}&schoolName=${encodeURIComponent(selectedSchool)}&withBg=${selectedValue}`,
                {
                    headers: {
                        token: sessionStorage.getItem('token'),
                    },
                }
            )
            .then((res) => {
                
                const pdfFileName = res.data;
                console.log("response console....",pdfFileName);
                if (pdfFileName === 'DATAPRESENT') {
                    handleShow();
                } else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                } else if (pdfFileName === 'PHOTONOTFOUND') {
                    toast.error('Some student photo not uploaded...', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setLoading(false);
                } 
                 else if (pdfFileName === 'SOMEGENERATED') {
                    handleShow1();
                }else if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } 
                else {
                    console.error('Invalid PDF URL');
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error('Failed to fetch data, try again', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCertificateType = (type) => {
        handleClose();
        setLoading(true);

        let newPrintPrevious;
        if (type === 'Old Certificate') {
            newPrintPrevious = 1;
            console.log("old previous print", newPrintPrevious);
        } else if (type === 'New Certificate') {

            newPrintPrevious = 2;
            console.log("new previous print", newPrintPrevious);
            
        }

        axios
            .get(
                `${SchoolPrintResultApi}?userId=${userId}&prefix=${preFix}&certNo=${certNo}&printPrevious=${newPrintPrevious}&schoolName=${encodeURIComponent(selectedSchool)}&withBg=${selectedValue}`,
                {
                    headers: {
                        token: sessionStorage.getItem('token'),
                    },
                }
            )
            .then((res) => {
                const pdfFileName = res.data;
                if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                } else {
                    console.error('Invalid PDF URL');
                }
            })
            .catch((err) => {
                toast.error('Failed to fetch data, try again', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .finally(() => {
                setLoading(false); // Set loading state back to false after the operation is done
            });
    };

    // useEffect(() => {
    //     // Fetch last certificate number on component mount
    //     axios.get(GetLastCertificateNo,
    //         {
    //             headers: {
    //                 token: sessionStorage.getItem('token'),
    //             },
    //         })
    //         .then(res => {
                
    //             if (res.data === 'UNAUTHORIZED') {
    //                 navigate('/');
    //             } else if (res.data !== null) {
    //                 setLastCertNo(res.data);
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Failed to fetch last certificate number:', error);
    //             // Handle errors if needed
    //         });
    // }, []); // Empty dependency array ensures this effect runs only once on component mount


    return (
        <Container fluid>
            <Row>
                <KenyaHeader />
            </Row>
            <Container>

                <Row className=' pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                    <Row>
                        <Col className='ps-4'>
                            <h2 className='ps-1' style={{borderLeft:"3px solid blue"}}>Generate Certificate</h2>
                        </Col>
                    </Row>

                    <Row className='px-md-5 m-0 px-2  pt-4 '>
                        <Col >
                            <button
                                className='py-1 px-3 fs-5 shadow me-2 mb-3'
                                style={{ color: "#3d3d3d", backgroundColor: "#ffe500", border: "none", borderRadius: "15px" }}

                            >
                                <i className="fa-solid fa-school"></i> &nbsp;School Wise
                            </button>
                        </Col>
                    </Row>

                    {/* =============== School Wise ========= */}
                    
                    {/* <Row className=' m-0 ' >
                        <Col md={3}></Col>
                        <Col sm={12} md={6} style={{display:"flex"}}>
                            <p><b>Last certificate no:</b> {lastCertNo}</p>
                        </Col>
                        <Col md={3}></Col>
                    </Row> */}

                    {/* <Row className=' m-0 mt-2 '>
                    <Col md={3}></Col>
                    <Col md={6} style={{ display: "flex"}} className='text-center '>
                    <p className='py-1 px-2 rounded' style={{backgroundColor:"red",color:"white",fontSize:"14px"}}>Please note that the next certificate will be generated using the certificate number shown above</p>
                    </Col>
                    <Col md={3}></Col>

                    </Row> */}

                    <Row className=' m-0 pt-2 ' style={{ display: "flex", alignItems: "center" }}>
                        <Col md={3}></Col>

                        <Col sm={12} md={6} className=' mb-1'>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={school_master}
                                getOptionLabel={(option) => option.schoolName}
                                value={school_master.find((item) => item.schoolName === selectedSchool) || null}
                                onChange={(e, newValue) => setSelectedSchool(newValue ? newValue.schoolName : '')}
                                renderInput={(params) => <TextField {...params} style={{ width: '100%' }} label="Select School " />}
                            />

                        </Col>
                        <Col md={3}></Col>

                    </Row>

                    <Row className='m-0 pt-2' style={{ display: "flex", alignItems: "center" }}>
            <Col md={3}></Col>
            <Col sm={12} md={6} className='mb-1 mt-2'>
                {/* Checkbox */}
                <input 
                    type="checkbox" 
                    style={{ width: "20px", height: "20px" }} 
                    checked={isChecked} 
                    onChange={handleCheckboxChange} 
                />
                <label> &nbsp;&nbsp; With Background</label>
            </Col>
        </Row>



                    {lastCertNo === '-' && (
  <>
    {(sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1) ? (
      <>
  <Row className=' m-0 pt-3 pt-md-4'>
    <Col md={3}></Col>

    <Col sm={12} md={3} className=' mb-3'>
      <TextField id="outlined-basic" label="Prefix" style={{ width: '100%' }} size='small' variant="outlined" value={preFix} onChange={(e) => setPreFix(e.target.value)} />
    </Col>

    <Col sm={12} md={3} className=' mb-3'>
      <TextField id="outlined-basic" label="Certificate Number" style={{ width: '100%' }} size='small' variant="outlined" value={certNo === 0 ? '' : certNo} onChange={(e) => setCertNo(e.target.value)} />
    </Col>

    <Col md={3}></Col>
  </Row>
  </>
    ) : (
      <>
        <Row className='m-0 pt-3 pt-md-4'>
          <Col md={12} style={{ display: 'flex', justifyContent: 'center' }} className='text-center'>
            <p style={{color:"red"}}>Cretificate number not created , please contact to Admin for create 1st certificate </p>
          </Col>
        </Row>
      </>
    )}
  </>
)}



{lastCertNo === '-' && !(sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1) ? null : (
                    <Row className='px-md-5 m-0 px-2 pt-3' style={{ display: "flex", alignItems: "center" }}>

                        <Col sm={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
                            <button
                                onClick={handlePrintClick}
                                // onClick={handleShow}
                                className=' button-30'

                                disabled={loading}
                            >
                                {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>
                                    {/* <i className="fa-solid fa-print"></i> */}
                                    &nbsp;Generate </div>}
                            </button>
                        </Col>
                    </Row>
                    )}

                </Row>


                {/* Modal for choosing certificate type */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='pb-3'>
                        <b>
        {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 ? 
        "As some certificate already printed, would you like to print the old certificate or the new certificate?" : 
        "Certificate already generated, only admin allowed to regenerate please contact admin."}
    </b>
                        </p>
                        <button className=' button-30 me-2' onClick={() => handleCertificateType('Old Certificate')}>
                            <b>Old Certificate</b>
                        </button>

                        {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 && (
                        <button className=' button-30 ms-2' onClick={() => handleCertificateType('New Certificate')}>
                            <b>New Certificate</b>
                        </button>
                        )}

                    </Modal.Body>
                </Modal>

                {/* =========== another modal =========== */}
                <Modal show={show1} onHide={handleClose1} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='pb-3'>
                        <b>
                        {/* {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 0 && ( */}
    <p>This school has some certificates already generated. Only pending certificates can be generated.</p>
{/* )} */}

    </b>
                        </p>
                    

                        {/* {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 0 && ( */}
                        <button className=' button-30 ms-2' onClick={() => handleCertificateType('Old Certificate')}>
                        {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>
                                    {/* <i className="fa-solid fa-print"></i> */}
                                    &nbsp;Generate </div>}
                        </button>
                        {/* )} */}

                    </Modal.Body>
                </Modal>
            </Container>

            <div className='mt-5 pt-5'>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    )
}

export default KenyagCertificateSchool






