import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import KenyaHeader from './KenyaHeader'
import { GenerateCSVPendingCountSchoolWise, GenerateCSVPendingCountStudentWise, PendingCountStudentWise, getExportedReportAPIURL } from './Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const KenyapCertificateStudent = () => {

    const [data, setData] = useState([]);
    const tokenData = sessionStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const user = JSON.parse(sessionStorage.getItem('user'));
    useEffect(() => {
        fetchData();
    }, []);

    const navigate = useNavigate();

    const fetchData = () => {
        axios.get(PendingCountStudentWise,
            {
                headers: {
                    token: tokenData,
                },
            })
            .then(response => {
               
                if (response.data === 'UNAUTHORIZED') {
                    navigate('/');
                  } else if (response.data !== null) {
                    setData(response.data);
                  }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handlePrintCSV = () => {
        setLoading(true);
        axios.get(GenerateCSVPendingCountStudentWise, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
          .then((res) => {
            const pdfFileName = res.data;
                if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                }else {
                    console.error('Invalid PDF URL');
                }
            setLoading(false);
          })
          .catch((error) => {
            // Handle error
            console.error('Error fetching CSV data:', error);
            setLoading(false);
          });
      };

  return (
    <Container fluid>
    <Row>
        <KenyaHeader />
    </Row>
    <Container>

    <Row className='pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                    <Row>
                        <Col className='ps-4'>
                            <h2>Pending Certificate</h2>
                        </Col>
                    </Row>

                    <Row className='ps-md-5 ps-1 pt-4 m-0 p-0'>
                        <Col>
                            <button
                                className='py-1 px-3 fs-5 shadow me-2 mb-1'
                                style={{ color: "#3d3d3d", backgroundColor: "#ffe500", border: "none", borderRadius: "15px" }}
                            >
                                <i className="fa-solid fa-school"></i>Student Wise
                            </button>
                        </Col>
                    </Row>

                    <Row className='m-0 p-0 px-md-5'>
                    <Col sm={12} md={12} style={{ display: "flex", justifyContent: "end" }}>
                            <button
                             
                                className=' button-30'

                                disabled={loading}
          onClick={handlePrintCSV}
                            >
                                {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>
                                Export CSV</div>}
                            </button>
                        </Col>
                    </Row>

                    <Row className='m-0 p-0 px-md-5 mt-md-2 mt-2'>
                        <div style={{ overflowY: 'auto' }}>
                            <div style={{ overflowX: 'auto' }}>
                                <Table striped bordered hover size="sm" className='shadow mb-5 mt-2' style={{ textAlign: "center" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Sr.no</th>
                                            <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Reg.no</th>
                                            <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Student Name</th>
                                            <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>School Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index} className='border'>
                                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                <td style={{ textAlign: "center" }}>{item.regNo}</td>
                                                <td style={{ textAlign: "center" }}>{item.studentName}</td>
                                                <td style={{ textAlign: "center" }}>{item.schoolName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Row>
                </Row>
    </Container>

            <div className='mt-5 pt-5'>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
  )
}

export default KenyapCertificateStudent
