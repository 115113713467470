// import React, { useEffect, useRef, useState } from 'react'
// import { Col, Container, Form, Nav, Row, Spinner, Table } from 'react-bootstrap';
// import logo from './Assets/logo.png'
// import { Autocomplete, TextField } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import html2pdf from 'html2pdf.js';
// import { saveAs } from 'file-saver';
// import { useReactToPrint } from 'react-to-print';
// import { SchoolFindAlltApi, SchoolListApi, SchoolPrintResultApi, StudentPrintResultApi, getExportedReportAPIURL } from './Api';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import KenyaHeader from './KenyaHeader';

// const KenyagCertificateStudent = () => {

//     const [showStudentWise, setShowStudentWise] = useState(false);
//     const tokenData = sessionStorage.getItem('token');
//     const [pdfUrl, setPdfUrl] = useState(null)
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(false);
//     const [regNo, setRegNo] = useState('');

//     const logoutFun = () => {
//         sessionStorage.removeItem("user");
//         sessionStorage.clear()
//         navigate("/");
//     }
//     useEffect(() => {
//         let token = sessionStorage.getItem("token");
//         let user = sessionStorage.getItem("user");

//         if (token == null || user == null) {
//             sessionStorage.clear()
//             navigate("/")
//         }
//     }, [])


//     // ============== student wise print api =============
//     const handleStudentPrint = () => {
//         setLoading(true);

//         // Check if registration number is entered
//         if (!regNo) {
//             toast.error("Please enter a registration number", {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             });
//             setLoading(false);  // Set loading to false here as well
//             return;
//         }

//         axios.get(`${StudentPrintResultApi}?regNo=${encodeURIComponent(regNo)}`, {
//             headers: {
//                 token: tokenData,
//             },
//         })
//             .then((res) => {
//                 const pdfFileName = res.data;
//                 if(pdfFileName=== "NOTFOUND"){
//                     toast.warn("Data not found", {
//                         position: "top-right",
//                         autoClose: 3000,
//                         hideProgressBar: false,
//                         closeOnClick: true,
//                         pauseOnHover: true,
//                         draggable: true,
//                     });
//                 }
//                else if (pdfFileName) {
//                     const pdfUrl = getExportedReportAPIURL + `${pdfFileName}`;
//                     window.open(pdfUrl, '_blank');
//                     setPdfUrl(pdfUrl);
//                 }
//                 else if(pdfFileName === "UNAUTHORIZED"){
//                     navigate('/');
//                 }
//                 else {
//                     console.error('Invalid PDF URL');
//                 }
//             })
//             .catch((err) => {
//                 toast.error("Failed to fetch data, try again", {
//                     position: "top-right",
//                     autoClose: 3000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                 });
//             })
//             .finally(() => {
//                 // Set loading to false in the finally block
//                 setLoading(false);
//                 setRegNo(''); // Reset regNo to clear the input field
//             });
//     };


//     return (
//         <Container fluid>
//             <Row>
//                 <KenyaHeader />
//             </Row>
//             <Container>

//                 <Row className=' pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
//                     <Row>
//                         <Col className='ps-4'>
//                             <h2>Generate Certificate</h2>
//                         </Col>
//                     </Row>

//                     <Row className='ps-md-5 ps-1 pt-4 '>
//                         <Col className='ps-5'>
//                             <button
//                                 className='py-1 px-3 fs-5 shadow me-2 mb-3'
//                                 style={{ color: "#3d3d3d", backgroundColor: "#ffe500", border: "none", borderRadius: "15px" }}
//                                 onClick={() => {
//                                     setShowStudentWise(true);
//                                 }}
//                             >
//                                 <i className="fa-solid fa-user"></i> &nbsp;Student Wise
//                             </button>
//                         </Col>
//                     </Row>


//                     {/* ================ Student Wise ================= */}

//                         <Row className='px-md-5 m-0 px-3 pt-2' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//                             <Col sm={12} md={6} className='mb-1 px-md-5  '>
//                                 <Form.Label className='mt-0'><h6 >Reg No. :</h6></Form.Label>
//                                 <Form.Control
//                                     id="regNoInput"
//                                     aria-label="Default select example"
//                                     placeholder='Enter Reg No.'
//                                     required
//                                     maxLength={15}
//                                     style={{ width: '100%' }}
//                                     value={regNo}  // Controlled component
//                                     onChange={(e) => setRegNo(e.target.value)}  // Update state on input change
//                                 />
//                             </Col>
//                             <Col sm={12} md={2} style={{ display: "flex", justifyContent: "center" }}>
//                                 <button
//                                     onClick={handleStudentPrint}
//                                     className='py-1 px-4 fs-5 mt-4'
//                                     style={{ color: "#3d3d3d", backgroundColor: "transparent", border: "2px solid #3d3d3d", borderRadius: "15px" }}
//                                     disabled={loading}
//                                 >
//                                     {loading ? <Spinner animation='border' size='sm' variant="primary"/> : <div>
//                                     {/* <i className="fa-solid fa-print"></i> */}
//                                      &nbsp;Download</div>}
//                                 </button>
//                             </Col>
//                         </Row>

//                 </Row>

//             </Container>

//             <div className='mt-5 pt-5'>
//                 <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | TechnoAarv Solution</h6>
//             </div>
//         </Container>
//     )
// }

// export default KenyagCertificateStudent








import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner, Modal, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import KenyaHeader from './KenyaHeader';
import { GetLastCertificateNo, SchoolListApi, SchoolPrintResultApi, StudentPrintResultApi, getExportedReportAPIURL } from './Api';
import { useNavigate } from 'react-router-dom';

const KenyagCertificateStudent = () => {
    const [school_master, setSchool_master] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');
    const [pdfUrl, setPdfUrl] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [regNo, setRegNo] = useState('');
    const [certNo, setCertNo] = useState(0);
    const [preFix, setPreFix] = useState('');
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user.userId;
    const [show, setShow] = useState(false);
    const [certificateType, setCertificateType] = useState('');
    const [printPrevious, setPrintPrevious] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [lastCertNo, setLastCertNo] = useState('');
    const tokenData = sessionStorage.getItem('token');

    const [isChecked, setIsChecked] = useState(false); // State to track checkbox status
    const [selectedValue, setSelectedValue] = useState(0); // State to store selected value

    const handleCheckboxChange = () => {
        // Toggle checkbox status
        setIsChecked(!isChecked);
        // Set selected value based on checkbox status
        setSelectedValue(isChecked ? 0 : 1);
    };

    const handleStudentPrint = () => {
        setLoading(true);

        // Check if registration number is entered
        if (!regNo) {
            toast.error("Please enter a registration number", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false);  // Set loading to false here as well
            return;
        }

        console.log("api console....",`${StudentPrintResultApi}?userId=${userId}&prefix=${preFix}&certNo=${certNo}&printPrevious=${printPrevious}&regNo=${encodeURIComponent(regNo)}&withBg=${selectedValue}`)
        axios
            .get(
                `${StudentPrintResultApi}?userId=${userId}&prefix=${preFix}&certNo=${certNo}&printPrevious=${printPrevious}&regNo=${encodeURIComponent(regNo)}&withBg=${selectedValue}`,
                {
                    headers: {
                        token: sessionStorage.getItem('token'),
                    },
                }
            )
            .then((res) => {
                const pdfFileName = res.data;

                if (pdfFileName === 'DATAPRESENT') {
                    handleShow();
                } else if (pdfFileName === "NOTFOUND") {
                    toast.warn("Data not found", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }else if (pdfFileName === 'PHOTONOTFOUND') {
                    toast.error('Some student photo not uploaded...', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setLoading(false);
                }
                else if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + `${pdfFileName}`;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                }
             else if (pdfFileName === 'UNAUTHORIZED') {
                navigate('/');
            } else {
            console.error('Invalid PDF URL');
        }
    })
            
            .catch ((err) => {
    setLoading(false);
    toast.error('Failed to fetch data, try again', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
})
            .finally(() => {
    setLoading(false);
});
    };

const handleCertificateType = (type) => {
    handleClose();
    setLoading(true);

    let newPrintPrevious;
    if (type === 'Old Certificate') {
        newPrintPrevious = 1;
        console.log("old previous print", newPrintPrevious);
    } else if (type === 'New Certificate') {

        newPrintPrevious = 2;
        console.log("new previous print", newPrintPrevious);
    }
    axios
        .get(
            `${StudentPrintResultApi}?userId=${userId}&prefix=${preFix}&certNo=${certNo}&printPrevious=${newPrintPrevious}&regNo=${encodeURIComponent(regNo)}&withBg=${selectedValue}`,
            {
                headers: {
                    token: sessionStorage.getItem('token'),
                },
            }
        )
        .then((res) => {
            const pdfFileName = res.data;
            if (pdfFileName) {
                const pdfUrl = getExportedReportAPIURL + pdfFileName;
                window.open(pdfUrl, '_blank');
                setPdfUrl(pdfUrl);
            } else if (pdfFileName === 'UNAUTHORIZED') {
                navigate('/');
            } else {
                console.error('Invalid PDF URL');
            }
        })
        .catch((err) => {
            toast.error('Failed to fetch data, try again', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        })
        .finally(() => {
            // Set loading to false in the finally block
            setLoading(false);
            setRegNo(''); // Reset regNo to clear the input field
        });
};

// useEffect(() => {
//     // Fetch last certificate number on component mount
//     axios.get(GetLastCertificateNo,
//         {
//             headers: {
//                 token: sessionStorage.getItem('token'),
//             },
//         })
//         .then(res => {
//             if (res.data === 'UNAUTHORIZED') {
//                 navigate('/');
//             } else if (res.data !== null) {
//                 setLastCertNo(res.data);
//             }
//         })
//         .catch(error => {
//             console.error('Failed to fetch last certificate number:', error);
//             // Handle errors if needed
//         });
// }, []); // Empty dependency array ensures this effect runs only once on component mount


return (
    <Container fluid>
        <Row>
            <KenyaHeader />
        </Row>
        <Container>

            <Row className=' pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                <Row>
                    <Col className='ps-4'>
                        <h2 className='ps-1' style={{borderLeft:"3px solid blue"}}>Generate Certificate</h2>
                    </Col>
                </Row>

                <Row className='px-md-5 m-0 px-2  pt-4 '>
                    <Col >
                        <button
                            className='py-1 px-3 fs-5 shadow me-2 mb-3'
                            style={{ color: "#3d3d3d", backgroundColor: "#ffe500", border: "none", borderRadius: "15px" }}

                        >
                            <i className="fa-solid fa-school"></i> &nbsp;Student Wise
                        </button>
                    </Col>
                </Row>

                {/* =============== School Wise ========= */}

                {/* <Row className=' m-0 ' >
                        <Col md={3}></Col>
                        <Col sm={12} md={6} style={{display:"flex"}}>
                            <p><b>Last certificate no:</b> {lastCertNo}</p>
                        </Col>
                        <Col md={3}></Col>
                    </Row> */}

                    {/* <Row className=' m-0 mt-2 '>
                    <Col md={3}></Col>
                    <Col md={6} style={{ display: "flex"}} className='text-center '>
                    <p className='py-1 px-2 rounded' style={{backgroundColor:"red",color:"white",fontSize:"14px"}}>Please note that the next certificate will be generated using the certificate number shown above</p>
                    </Col>
                    <Col md={3}></Col>

                    </Row> */}

                <Row className=' m-0 pt-2 ' style={{ display: "flex", alignItems: "center" }}>
                    <Col md={3}></Col>

                    <Col sm={12} md={6} >
                        <Form.Label className='mt-0'><h6 >Reg No. :</h6></Form.Label>
                        <Form.Control
                            id="regNoInput"
                            aria-label="Default select example"
                            placeholder='Enter Reg No.'
                            required
                            maxLength={15}
                            style={{ width: '100%' }}
                            value={regNo}  // Controlled component
                            onChange={(e) => setRegNo(e.target.value)}  // Update state on input change
                        />
                    </Col>
                    <Col md={3}></Col>

                </Row>

                <Row className='m-0 pt-2' style={{ display: "flex", alignItems: "center" }}>
            <Col md={3}></Col>
            <Col sm={12} md={6} className='mb-1 mt-2'>
                {/* Checkbox */}
                <input 
                    type="checkbox" 
                    style={{ width: "20px", height: "20px" }} 
                    checked={isChecked} 
                    onChange={handleCheckboxChange} 
                />
                <label> &nbsp;&nbsp; With Background</label>
            </Col>
        </Row>

                {lastCertNo === '-' && (
  <>
    {(sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1) ? (
      <>
        <Row className=' m-0 pt-3 pt-md-4'>
          <Col md={3}></Col>
          <Col sm={12} md={3} className=' mb-3'>
            <TextField id="outlined-basic" label="Prefix" style={{ width: '100%' }} size='small' variant="outlined" value={preFix} onChange={(e) => setPreFix(e.target.value)} />
          </Col>
          <Col sm={12} md={3} className=' mb-3'>
            <TextField id="outlined-basic" label="Certificate Number" style={{ width: '100%' }} size='small' variant="outlined" value={certNo === 0 ? '' : certNo} onChange={(e) => setCertNo(e.target.value)} />
          </Col>
          <Col md={3}></Col>
        </Row>
      </>
    ) : (
      <>
        <Row className='m-0 pt-3 pt-md-4'>
          <Col md={12} style={{ display: 'flex', justifyContent: 'center' }} className='text-center'>
            <p style={{color:"red"}}>Cretificate number not created , please contact to Admin for create 1st certificate </p>
          </Col>
        </Row>
      </>
    )}
  </>
)}

{lastCertNo === '-' && !(sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1) ? null : (
  <Row className='px-md-5 m-0 px-2 pt-3' style={{ display: "flex", alignItems: "center" }}>
    <Col sm={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
      <button
        onClick={handleStudentPrint}
        className=' button-30'
        disabled={loading}
      >
        {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>&nbsp;Generate</div>}
      </button>
    </Col>
  </Row>
)}

            </Row>


            {/* Modal for choosing certificate type */}
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                <p className='pb-3'>
                            <b>As some certificate already printed {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 && (', would you like to print the old certificate or the new certificate?    ')}</b>
                        </p>
                    <button className=' button-30 me-2'  onClick={() => handleCertificateType('Old Certificate')}>
                        <b>Old Certificate</b>
                    </button>

                    {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 && (
                    <button className=' button-30 ms-2'  onClick={() => handleCertificateType('New Certificate')}>
                        <b>New Certificate</b>
                    </button>
                    )}
                </Modal.Body>
            </Modal>
        </Container>

        <div className='mt-5 pt-5'>
            <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
        </div>
    </Container>
)
}

export default KenyagCertificateStudent







