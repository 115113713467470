import React, { useEffect, useState } from 'react';
import './KenyaLogin.css';
import { Col, Container, Form, FormControl, Nav, Row, Spinner } from 'react-bootstrap';
import logo from './Assets/logo.png'
import changePass from './Assets/changePass.svg'
// import { Autocomplete, InputLabel, TextField } from '@mui/material';
import TextField from '@mui/material/TextField';

import { useNavigate } from 'react-router-dom';
import { BaseIp, ChangePassApi, UserCreateApi } from './Api';
import axios from 'axios';
import { toast } from 'react-toastify';
import KenyaHeader from './KenyaHeader';

const KenyaPassChange = () => {
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const tokenData = sessionStorage.getItem('token');
    const [token, setToken] = useState(null);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const email = user.login_id;
    const navigate = useNavigate();


    const input = {
      border: '1px solid',
      margin: '10px 10px 10px 0px',
    };
    
    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            setToken(sessionStorage.getItem('token'));
        } else {
            setToken(null);
        }
    }, []);
  
    const handlePasswordChange = (value) => {
      setPassword(value);
      setPasswordsMatch(value === confirmPassword);
    };
  
    const handleConfirmPasswordChange = (value) => {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!oldPassword || !password || !confirmPassword) {
            toast.error('Please fill in all the password fields.');
            return;
        }
    
        if (!passwordsMatch) {
            toast.error('Passwords do not match');
            return;
        }
    
        try {
            setLoading(true);
    
            // Make API call
            const response = await axios.post(
                `${ChangePassApi}?loginId=${email}&oldPass=${oldPassword}&newPass=${password}`,
                null,
                {
                    headers: {
                        token: tokenData,
                    },
                }
            );
    
    
            // Handle the API response accordingly
            if (response.data) {
                const status = response.data;
    
                if (status === 'UNAUTHORIZED') {
                    // Customize the response for unauthorized access
                    navigate('/')
                    toast.error('Unauthorized access. Please check your credentials.');
                } else if (status === 'NOTFOUND') {
                    // Customize the response for user not found
                    toast.error('Old password does not match.');
                } else if (status === 'UPDATED') {
                    // Customize the response for password updated successfully
                    toast.success('Password changed successfully');
                    setOldPassword('');
                    setPassword('');
                    setConfirmPassword('');
                } else {
                    // Handle unknown response status
                    toast.error('Unknown response status.');
                }
            } else {
                // Handle the case where success is false
                toast.error(response.data.message || 'Failed to change password');
            }
        } catch (error) {
            // Handle errors during the API call
            toast.error('An error occurred while changing the password');
        } finally {
            setLoading(false);
        }
    };
    
      
    return (
        <Container fluid>
            <Row >
            <KenyaHeader />
            </Row>

            <Container className='mb-5'>
            <Row className='pt-md-3 mt-0'>
                <Col sm={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={changePass} className='w-100' />
                </Col>
                <Col md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className='login-container mb-4 mb-md-0'>
                        <div className='login-form shadow mb-5 mb-md-3 py-3' style={{ border: "3px solid #ffe500", borderRadius: "15px" }}>
                            <form
                                onSubmit={handleSubmit}
                            >
                                <div className='input'>

                                    <h2 className='text-center mb-4'>Change Password</h2>

                                    <div className="col-md-12 ">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="password">Old Password:</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>


                                    <div className="col-md-12 ">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="password">Create Password:</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="password"
                          value={password}
                          isInvalid={!passwordsMatch}
                          onChange={(e) => handlePasswordChange(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>


                    <div className="col-md-12">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="confirmPassword"
                          value={confirmPassword}
                          isInvalid={!passwordsMatch}
                          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                          required
                        />
                        {/* <Form.Control.Feedback type="invalid">
                          Password do not match
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </div>

                                    <div className='login-button mt-3 mb-3'>
                                        <button type="submit" className='login-btn' >{loading ? <Spinner animation='border' size='sm' variant="primary" /> : <b>Confirm</b>}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
            </Container>

            <div>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    )
}

export default KenyaPassChange
