// import React, { useEffect, useState } from 'react'
// import { Col, Container, Row, Spinner, Table } from 'react-bootstrap'
// import KenyaHeader from './KenyaHeader'
// import axios from 'axios';
// import { GenerateCSVGeneratedCertificateAll, GeneratedCertificateAll, GeneratedSchoolListApi, GetLastCertificateNo, GgenerateCSVGeneratedCertificateAll, PrintGeneratedResultBySchool, getExportedReportAPIURL } from './Api';
// import { Checkbox, FormControlLabel } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { MDBDataTable } from 'mdbreact';
// import { Modal, Button } from 'react-bootstrap';
// import { TextField } from '@mui/material';
// import { toast } from 'react-toastify';

// const KenyaGenetated_C_All = () => {

//     const [data, setData] = useState([]);
//     const [selectedItems, setSelectedItems] = useState([]);
//     const tokenData = sessionStorage.getItem('token');
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(false);
//     const [pdfUrl, setPdfUrl] = useState(null);
//     const user = JSON.parse(sessionStorage.getItem('user'));
//     const userId = user.userId;

//     useEffect(() => {
//         fetchGeneratedCertificates();
//     }, []);

//     const fetchGeneratedCertificates = () => {
//         axios.get(GeneratedSchoolListApi, {
//             headers: {
//                 token: sessionStorage.getItem('token'),
//             },
//         })
//             .then(response => {
                
//                 if (response.data === 'UNAUTHORIZED') {
//                     navigate('/');
//                   } else if (response.data !== null) {
//                     setData(response.data);
//                   }
//                 setSelectedItems(new Array(response.data.length).fill(false)); // Initialize selectedItems array
//             })
//             .catch(error => {
//                 console.error('Error fetching generated certificates:', error);
//             });
//     };

//     const handlePrintCSV = () => {
//         setLoading(true);
//         axios.get(GgenerateCSVGeneratedCertificateAll, {
//             headers: {
//                 token: sessionStorage.getItem('token'),
//             },
//         })
//             .then((res) => {
//                 const pdfFileName = res.data;
//                 if (pdfFileName) {
//                     const pdfUrl = getExportedReportAPIURL + pdfFileName;
//                     window.open(pdfUrl, '_blank');
//                     setPdfUrl(pdfUrl);
//                 } else if (pdfFileName === 'UNAUTHORIZED') {
//                     navigate('/');
//                 } else {
//                     console.error('Invalid PDF URL');
//                 }
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 // Handle error
//                 console.error('Error fetching CSV data:', error);
//                 setLoading(false);
//             });
//     };

        


//     const columns = [
//         {
//             label: 'Sr.no',
//             field: 'srNo',
//             sort: 'asc',
//         },
//         {
//             label: 'School Name',
//             field: 'schoolName',
//             sort: 'asc',
//         },
//         {
//             label: 'Generated Count',
//             field: 'generatedCount',
//             sort: 'asc',
//         },
//         {
//             label: 'Download Certificate',
//             field: 'download certificate', // Match the field name used in customRows
//             sort: 'asc',
//         },

//     ];

//     const customRows = data.map((item, index) => {

//         const { id, schoolName, generatedCount} = item;

//         const handleGenerateCertificate = () => {
//             setLoading(true);
//             axios.get(`${PrintGeneratedResultBySchool}?schoolName=${schoolName}`, {
//                 headers: {
//                     token: sessionStorage.getItem('token'),
//                 },
//             })
//                 .then((res) => {
//                     const pdfFileName = res.data;
//                     if (pdfFileName) {
//                         const pdfUrl = getExportedReportAPIURL + pdfFileName;
//                         window.open(pdfUrl, '_blank');
//                         setPdfUrl(pdfUrl);
//                     } else if (pdfFileName === 'UNAUTHORIZED') {
//                         navigate('/');
//                     } else {
//                         console.error('Invalid PDF URL');
//                     }
//                     setLoading(false);
//                 })
//                 .catch((error) => {
//                     // Handle error
//                     console.error('Error fetching CSV data:', error);
//                     setLoading(false);
//                 });
//         };

//         const generateCertificateButton = (
//             <button
//                 className="py-1 px-2 rounded" style={{backgroundColor:"green",border:"none",color:"white"}}
//                 onClick = {handleGenerateCertificate}
//                 >Download Certificate</button>
//         );
//         let statusText, actionIcon, actionColor;

//         return {
//             srNo: index + 1,
//             schoolName,
//             generatedCount,
//             'download certificate': generateCertificateButton,
//         };
//     });



//     return (
//         <Container fluid>
//             <Row>
//                 <KenyaHeader />
//             </Row>
//             <Container>

//                 <Row className=' pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
//                     <Row className='m-0 p-0 px-md-5'>
//                         <Col  style={{ display: "flex", justifyContent: "space-between" }}>
//                             <h2 className='ps-1' style={{borderLeft:"3px solid blue"}}>Generated Certificate All</h2>
//                             <button

//                                 className=' button-30'

//                                 disabled={loading}
//                                 onClick={handlePrintCSV}
//                             >
//                                 {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Export CSV</div>}
//                             </button>
//                         </Col>
//                     </Row>

//                     <Row className='m-0 p-0 px-md-5 mt-md-4 mt-2'>
//                         <MDBDataTable
//                             className=' custom-datatable'
//                             striped
//                             bordered
//                             hover
//                             small
//                             data={{ columns, rows: customRows }}
//                             style={{ textAlign: "center", fontSize: "14px", }} />

//                     </Row>

//                     {/* <Row className='m-0 p-0 px-md-5 mt-2'>
//             <div style={{ overflowY: 'auto' }}>
//               <div style={{ overflowX: 'auto' }}>
//               <Table striped bordered hover size="sm" className='shadow mb-5 mt-2' style={{ textAlign: "center" }}>
//                                     <thead >
//                                         <tr>
//                                             <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Sr.No</th>
//                                             <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Student Name</th>
//                                             <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>School Name</th>
//                                             <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Certificate No</th>
//                                             <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Generated Date</th>
//                                             <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Generated By</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {data.map((item, index) => (
//                                             <tr className='border' key={index}>
//                                                 <td style={{ textAlign: "center" }}>{index + 1}</td>
//                                                 <td style={{ textAlign: "center" }}>{item.studentName}</td>
//                                                 <td style={{ textAlign: "center" }}>{item.schoolName}</td>
//                                                 <td style={{ textAlign: "center" }}>{item.certificateNo}</td>
//                                                 <td style={{ textAlign: "center" }}>{item.printDate}</td>
//                                                 <td style={{ textAlign: "center" }}>{item.userName}</td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </Table>
//               </div>
//             </div>
//           </Row> */}


//                 </Row>

                

//             </Container>

//             <div className='mt-5 pt-5'>
//                 <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
//             </div>
//         </Container>
//     )
// }

// export default KenyaGenetated_C_All






import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import KenyaHeader from './KenyaHeader';
import axios from 'axios';
import { GeneratedSchoolListApi, GgenerateCSVGeneratedCertificateAll, PrintGeneratedResultBySchool, getExportedReportAPIURL } from './Api';
import { MDBDataTable } from 'mdbreact';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const KenyaGenetated_C_All = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false); // State to track checkbox status
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedSchoolName, setSelectedSchoolName] = useState('');

    useEffect(() => {
        fetchGeneratedCertificates();
    }, []);

    const handleCheckboxChange = () => {
        // Toggle checkbox status
        setIsChecked(!isChecked);
        // Set selected value based on checkbox status
        setSelectedValue(isChecked ? 0 : 1);
    };

    const fetchGeneratedCertificates = () => {
        axios.get(GeneratedSchoolListApi, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
            .then(response => {
                if (response.data === 'UNAUTHORIZED') {
                    navigate('/');
                } else if (response.data !== null) {
                    setData(response.data);
                    console.log("res table...",response.data)
                }
            })
            .catch(error => {
                console.error('Error fetching generated certificates:', error);
            });
    };

    const handlePrintCSV = () => {
        setLoading(true);
        axios.get(GgenerateCSVGeneratedCertificateAll, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
            .then((res) => {
                const pdfFileName = res.data;
                if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                } else {
                    console.error('Invalid PDF URL');
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
                setLoading(false);
            });
    };

    const handleGenerateCertificate = (schoolName) => {
        setShowModal(true);
        setSelectedSchoolName(schoolName); // Set selected school name
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleGenerateCertificateConfirmation = () => {
        setLoadingModal(true);
        console.log(`${PrintGeneratedResultBySchool}?schoolName=${selectedSchoolName}&withBg=${selectedValue}`);
        axios.get(`${PrintGeneratedResultBySchool}?schoolName=${selectedSchoolName}&withBg=${selectedValue}`, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
            .then((res) => {
                console.log("res...",res)
                const pdfFileName = res.data;
              
                if (pdfFileName === 'NOTFOUND') {
                    toast.error(isChecked ? "Certificate with background not found" : "Certificate without background not found", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsChecked('');
                    setLoadingModal(false);
                    setShowModal(false);
                } 
                else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                    setLoadingModal(false);
                }
                else if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                }
                else {
                    console.error('Invalid PDF URL');
                    setLoadingModal(false);
                }
                setLoadingModal(false);
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
                setLoadingModal(false);
            });
    };

    const columns = [
        {
            label: 'Sr.no',
            field: 'srNo',
            sort: 'asc',
        },
        {
            label: 'School Name',
            field: 'schoolName',
            sort: 'asc',
        },
        {
            label: 'Generated Count',
            field: 'generatedCount',
            sort: 'asc',
        },
        {
            label: 'Download Certificate',
            field: 'download certificate',
            sort: 'asc',
        },
    ];

    const customRows = data.map((item, index) => {
        const { id, schoolName, generatedCount } = item;

        return {
            srNo: index + 1,
            schoolName,
            generatedCount,
            'download certificate': (
                <button
                    className="py-1 px-2 rounded"
                    style={{ backgroundColor: "green", border: "none", color: "white" }}
                    onClick={() => handleGenerateCertificate(schoolName)} // Pass schoolName to handleGenerateCertificate
                >Download Certificate</button>
            ),
        };
    });

    return (
        <Container fluid>
            <Row>
                <KenyaHeader />
            </Row>
            <Container>
                <Row className=' pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                    <Row className='m-0 p-0 px-md-5'>
                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                            <h2 className='ps-1' style={{ borderLeft: "3px solid blue" }}>Generated Certificate All</h2>
                            <button
                                className=' button-30'
                                disabled={loading}
                                onClick={handlePrintCSV}
                            >
                                {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Export CSV</div>}
                            </button>
                        </Col>
                    </Row>

                    <Row className='m-0 p-0 px-md-5 mt-md-4 mt-2'>
                        <MDBDataTable
                            className=' custom-datatable'
                            striped
                            bordered
                            hover
                            small
                            data={{ columns, rows: customRows }}
                            style={{ textAlign: "center", fontSize: "14px" }} />
                    </Row>
                </Row>
            </Container>

            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Download Certificate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='m-0 pt-2' style={{ display: "flex", alignItems: "center" }}>
                        <Col md={3}></Col>
                        <Col sm={12} md={6} className='mb-1 mt-2'>
                            {/* Checkbox */}
                            <input
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label> &nbsp;&nbsp; With Background</label>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="primary" onClick={handleGenerateCertificateConfirmation} disabled={loadingModal}>
                        {loadingModal ? <Spinner animation='border' size='sm' variant="light" /> : <div>Download Certificate</div>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='mt-5 pt-5'>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    );
};

export default KenyaGenetated_C_All;

