import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import KenyaHeader from './KenyaHeader';
import axios from 'axios';
import { GenerateCSVPendingCountSchoolWise, GetLastCertificateNo, PendingCountSchoolWise, PrintResultBySchoolPendingCert, getExportedReportAPIURL } from './Api';
import { useNavigate } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import { Modal, Button } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';

const KenyapCertificateSchool = () => {

    const [data, setData] = useState([]);
    const tokenData = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedSchoolId, setSelectedSchoolId] = useState(null);
    const [certNo, setCertNo] = useState(0)
    const [isChecked, setIsChecked] = useState(false); // State to track checkbox status
    const [selectedValue, setSelectedValue] = useState(0);
    const [preFix, setPreFix] = useState('');
    const [lastCertNo, setLastCertNo] = useState('');
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user.userId;


    useEffect(() => {
        fetchData();
    }, []);

    const handleCheckboxChange = () => {
        // Toggle checkbox status
        setIsChecked(!isChecked);
        // Set selected value based on checkbox status
        setSelectedValue(isChecked ? 0 : 1);
    };

    const fetchData = () => {
        axios.get(PendingCountSchoolWise,
            {
                headers: {
                    token: tokenData,
                },
            })
            .then(response => {
              
                if (response.data === 'UNAUTHORIZED') {
                    navigate('/');
                  } else if (response.data !== null) {
                    setData(response.data);
                  }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    // useEffect(() => {
    //     // Fetch last certificate number on component mount
    //     axios.get(GetLastCertificateNo,
    //         {
    //             headers: {
    //                 token: sessionStorage.getItem('token'),
    //             },
    //         })
    //         .then(response => {
    //             console.log("res,.,.,", response);
    //             setLastCertNo(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Failed to fetch last certificate number:', error);
    //             // Handle errors if needed
    //         });

    // }, []);

    const handlePrintCSV = () => {
        setLoading(true);
        axios.get(GenerateCSVPendingCountSchoolWise, {
            headers: {
                token: sessionStorage.getItem('token'),
            },
        })
            .then((res) => {
                const pdfFileName = res.data;
                if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                } else {
                    console.error('Invalid PDF URL');
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    
    const handleGenerateCertificateConfirmation = () => {
        setLoading(true);
        // Call your API function to generate the certificate
console.log("api console....",`${PrintResultBySchoolPendingCert}?schoolName=${selectedSchoolId}&userId=${userId}&prefix=${preFix}&certNo=${certNo}&withBg=${selectedValue}`);

        axios.get(`${PrintResultBySchoolPendingCert}?schoolName=${selectedSchoolId}&userId=${userId}&prefix=${preFix}&certNo=${certNo}&withBg=${selectedValue}`,
        {
            headers: {
                token: tokenData,
            },
        })
            .then((res) => {
                const pdfFileName = res.data;
                setPreFix('');
                setCertNo(0);
                fetchData();
                setShowModal(false); 
                if (pdfFileName === 'DATAPRESENT') {
                    // handleShow();
                } else if (pdfFileName) {
                    const pdfUrl = getExportedReportAPIURL + pdfFileName;
                    window.open(pdfUrl, '_blank');
                    setPdfUrl(pdfUrl);
                } else if (pdfFileName === 'UNAUTHORIZED') {
                    navigate('/');
                }
                else if (pdfFileName === 'PHOTONOTFOUND') {
                    toast.error('Some student photo not uploaded...', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setLoading(false);
                }
                else {
                    console.error('Invalid PDF URL');
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error('Failed to fetch data, try again', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
        


    const columns = [
        {
            label: 'Sr.no',
            field: 'srNo',
            sort: 'asc',
        },
        {
            label: 'School Name',
            field: 'schoolName',
            sort: 'asc',
        },
        {
            label: 'Pending Count',
            field: 'count',
            sort: 'asc',
        },
        {
            label: 'Generate Certificate',
            field: 'generate certificate', // Match the field name used in customRows
            sort: 'asc',
        },
    ];

    const customRows = data.map((item, index) => {
        const { id, schoolName, count, status } = item;

        // Define a function to handle generating certificates
        const handleGenerateCertificate = (item) => {

            setSelectedSchoolId(item.schoolName);
            setShowModal(true);
        };

        // Define a button element for the "Generate Certificate" column
        const generateCertificateButton = (
            <button
                className="py-1 px-2 rounded" style={{backgroundColor:"green",border:"none",color:"white"}}
                onClick={() => handleGenerateCertificate(item)}
                >Generate Certificate</button>
        );

        return {
            srNo: index + 1,
            schoolName,
            count,
            'generate certificate': generateCertificateButton, // Assign the button to the corresponding field
        };
    });




    return (
        <Container fluid>
            <Row>
                <KenyaHeader />
            </Row>
            <Container>
                <Row className='pb-5 mb-5 py-4' style={{ display: "flex", flexDirection: "column", border: "3px solid #ffe500", borderRadius: "15px" }}>
                    <Row className='m-0 p-0 px-md-5 pt-3'>
                        <Col style={{ display: "flex", justifyContent: "space-between" }}>
                            <h2 className='ps-1' style={{borderLeft:"3px solid blue"}}>Pending Certificate Generation</h2>
                            <button

                                className=' button-30'

                                disabled={loading}
                                onClick={handlePrintCSV}
                            >
                                {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Export CSV</div>}
                            </button>
                        </Col>
                    </Row>


                    {/* <Row className='m-0 p-0 px-md-5  mt-2'>
                <div style={{ overflowY: 'auto' }}>
                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover size="sm" className='shadow mb-5 mt-2' style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Sr.No</th>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>School Name</th>
                                    <th style={{ alignItems: "center", color: "#ffe500", backgroundColor: "rgb(47, 47, 65)", fontSize: "14px" }} className='text-center'>Pending Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index} className='border'>
                                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                                        <td style={{ textAlign: "center" }}>{item.schoolName}</td>
                                        <td style={{ textAlign: "center" }}>{item.count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Row> */}

                    <Row className='m-0 p-0 px-md-5 mt-md-4 mt-2'>
                        <MDBDataTable
                            className='custom-datatable'
                            striped
                            bordered
                            hover
                            small
                            data={{ columns, rows: customRows }}
                            style={{ textAlign: "center", fontSize: "14px", }} />

                    </Row>
                </Row>


                {/* ================================ Modal ============================= */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Generate Certificate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Conditionally render based on whether the user needs to enter prefix and certificate number */}
                    { lastCertNo=== '-'? (
                        <>
                        {sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1 && (<>
                            <Row className='m-0 mt-2'>
                                <Col md={12} style={{ display: 'flex', justifyContent: 'center' }} className='text-center'>
                                    <p className='py-1 px-2 rounded' style={{ backgroundColor: 'red', color: 'white', fontSize: '18px' }}>Please Enter Prefix And Certificate No.</p>
                                </Col>
                            </Row>
                            <Row className='m-0 pt-3 pt-md-4'>
                                <Col sm={12} md={6} className='mb-3'>
                                    <TextField id='outlined-basic' required label='Prefix' style={{ width: '100%' }} size='small' variant='outlined' value={preFix} onChange={(e) => setPreFix(e.target.value)} />
                                </Col>
                                <Col sm={12} md={6} className='mb-3'>
                                    <TextField id='outlined-basic' required label='Certificate Number' style={{ width: '100%' }} size='small' variant='outlined' value={certNo === 0 ? '' : certNo} onChange={(e) => setCertNo(e.target.value)} />
                                </Col>
                            </Row>
                          </>  )}
                            
                        </>
                    ) : (
                        <>
                            {/* Display this section when showing the last certificate number */}
                            <Row className='m-0'>
                                <Col sm={12} md={12} style={{ display: 'flex' }}>
                                    {/* <p><b>Last certificate no: {lastCertNo}</b></p> */}
                                    <p><b>Do You Want To Generate Certificate </b></p>
                                </Col>
                            </Row>
                            
                            <Row className='m-0 pt-2' style={{ display: "flex", alignItems: "center" }}>
            <Col md={3}></Col>
            <Col sm={12} md={6} className='mb-1 mt-2'>
                {/* Checkbox */}
                <input 
                    type="checkbox" 
                    style={{ width: "20px", height: "20px" }} 
                    checked={isChecked} 
                    onChange={handleCheckboxChange} 
                />
                <label> &nbsp;&nbsp; With Background</label>
            </Col>
        </Row>

                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
  {lastCertNo === '-' && !(sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).role === 1) ? (
    <>
      <Row className='m-0  pt-md-4'>
        <Col md={12} style={{ display: 'flex', justifyContent: 'center' }} className='text-center'>
          <p style={{color:"red"}}>Cretificate number not created , please contact to Admin for create 1st certificate </p>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <button className='button-30' onClick={() => setShowModal(false)}>Cancel</button>
      <button className='button-30' onClick={handleGenerateCertificateConfirmation} disabled={loading}>
        {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Generate</div>}
      </button>
    </>
  )}
</Modal.Footer>
            </Modal>
            </Container>

            <div className='mt-5 pt-5'>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0 ' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    )
}

export default KenyapCertificateSchool