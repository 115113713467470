import React from 'react';
import { Button, Col, Container, Nav, Row } from 'react-bootstrap';
import logo from './Assets/logo.png';
import homeImage from './Assets/homepageImage.png';
import arrow from './Assets/arrow.png'
import loginArrow from './Assets/loginArrow.png'
import { useNavigate } from 'react-router-dom';

const KenyaHomePage = () => {

    const navigate = useNavigate();
    const LoginPage = () => {
        navigate('/login')
    }
    return (
        <Container fluid>
            <Row className='m-0'>
                <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='px-md-5 ps-2 pt-md-4 pt-2 mb-5'>
                    <div style={{ display: "flex" }}>
                        <img src={logo} style={{ width: "75px", height: "65px" }} />
                        <h2 className='ps-3 fs-1' style={{ display: "flex", color: "#000000", fontFamily: "Inder, var(--default-font-family)", lineHeight: "30px", alignItems: "center", justifyContent: "center", textShadow: "2px 4px 10px gray" }}>THE KENYA NATIONAL EXAMINATION COUNCIL </h2>
                    </div>
                    <button onClick={LoginPage} className='me-md-5 text-center' style={{ display: "flex",border:"none",background:"transparent" }}>
                        <img src={loginArrow} className='w-100' style={{ height: "24px" }} />&nbsp;&nbsp;
                        <p style={{ color: "#000000" }}>Login</p>
                    </button>
                </Col>
            </Row>
            <Container>
                <Row className='mx-md-5'>
                    <Col sm={12} md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <h2 className='fs-1' style={{ fontFamily: "Inder, var(--default-font-family)", lineHeight: "60px" }}><b style={{ color: "#000000" }}>THE KENYA NATIONAL <br />EXAMINATION COUNCIL</b></h2>

                        <div>
                            <button className='px-4 py-1 text-center mt-md-5 mt-3 mb-3 mb-md-0 shadow' style={{ display: "flex", backgroundColor: "transparent", border: "2px solid #ffe500", borderRadius: "15px", color: "black" }}>
                                <div onClick={LoginPage} className='text-center' style={{ display: "flex", fontSize: "20px" }}>
                                    <div style={{ color: "black" }}>Get&nbsp;Result</div> &nbsp;&nbsp;
                                    <div><img src={arrow} className='w-100' style={{ height: "24px" }} /></div>
                                </div>
                            </button>
                        </div>

                    </Col>
                    <Col sm={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={homeImage} style={{ height: "400px", width: "300px" }} />
                    </Col>
                </Row>
            </Container>

            <div className='mt-5'>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>
        </Container>
    )
}

export default KenyaHomePage
