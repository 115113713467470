import React, { useEffect, useState } from 'react';
import './KenyaLogin.css';
import { Col, Container, Form, FormControl, Nav, Navbar, Row, Spinner } from 'react-bootstrap';
import logo from './Assets/logo.png';
import otpImage from './Assets/otpImage.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OtpApi, OtpVerificationForResetPassword, ResetPassword, SendOtpForResetPassword, VerificationOtpApi } from './Api';
import { useNavigate } from 'react-router-dom';
import KenyaHeader from './KenyaHeader';
import loginArrow from './Assets/loginArrow.png'
import changePass from './Assets/changePass.svg'

const KenyaForgotPass = () => {
    const navigate = useNavigate();
    const LoginPage = () => {
        navigate('/login')
    }
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(180); // Timer in seconds (3 minutes)
    const [loading, setLoading] = useState(false)
    const [isloading, setisLoading] = useState(false)
    const [email, setEmail] = useState(false)
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [step, setStep] = useState(1); // Default step is 1

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    // ======================== step 1 =====================================

    const saveFun1 = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            // Make API call to send OTP and verify
            const response = await axios.post(
                `${SendOtpForResetPassword}?loginId=${email}`
            );
    
    
            // Handle the API response accordingly
            if (response.data && response.data === 'SEND') {
                toast.success('OTP sent successfully to your registered email id');

                // Move to the next step
                setStep(2);
            } else {
                toast.error(response.data || 'Failed to send OTP');
            }
        } catch (error) {
            toast.error('Enter your correct email id');
        } finally {
            setLoading(false);
        }
    };
    


    // ======================== step 2 =====================================

    const saveFun = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            // Make API call for OTP verification
            const response = await axios.post(
                `${OtpVerificationForResetPassword}?otp=${otp}&loginId=${email}`
            );
    
            // Handle the API response accordingly
            if (response.data === 'NOTFOUND') {
                // Customize the response for user not found
                toast.error('User not found.');
            } else if (response.data === 'VERIFIED') {
                // Customize the response for successful OTP verification
                toast.success('OTP verified successfully');
                setStep(3);
                // Additional logic if needed
            } else if (response.data === 'INVALID') {
                // Customize the response for invalid OTP
                toast.error('Invalid OTP.');
            } else {
                // Handle unknown response status
                toast.error('Unknown response status.');
            }
        } catch (error) {
            // Handle errors during the API call
            toast.error('Failed to verify OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    


    // ==================== RESEND otp ========================
    const resendOtp = () => {
        setisLoading(true);
        axios
            .post(
                `${SendOtpForResetPassword}?loginId=${email}`
        )
            .then((res) => {

                // Handle the response as needed
                // For example, show a success message or handle errors
                if (res.data === 'SEND') {
                    toast.success('OTP Resent successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    // Reset the timer
                    setTimer(180);
                } else {
                    // Handle errors or other responses
                    toast.error('Failed to resend OTP, please try again', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            })
            .catch((error) => {
                alert(`Server Error`);
            })
            .finally(() => {
                setisLoading(false); // Set isloading to false after the request is completed
            });
    };


    // ==========================  New and Confirm Pass Api ========================
    const input = {
        border: '1px solid',
        margin: '10px 10px 10px 0px',
    };
    const handlePasswordChange = (value) => {
        setPassword(value);
        setPasswordsMatch(value === confirmPassword);
    };

    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
        setPasswordsMatch(value === password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!password || !confirmPassword) {
            toast.error('Please fill in all the password fields.');
            return;
        }
    
        if (!passwordsMatch) {
            toast.error('Passwords do not match');
            return;
        }
    
        try {
            setLoading(true);
    
            // Make API call for resetting password
            const response = await axios.post(
                `${ResetPassword}?loginId=${email}&password=${password}`
               
            );
    
            // Handle the API response accordingly
            if (response.data === 'NOTFOUND') {
                // Customize the response for user not found
                toast.error('User not found.');
            } else if (response.data === 'UPDATED') {
                // Customize the response for successful password reset
                toast.success('Password reset successfully');
                navigate('/login')
                // Additional logic if needed
            } else {
                // Handle unknown response status
                toast.error('Unknown response status.');
            }
        } catch (error) {
            // Handle errors during the API call
            toast.error('Failed to reset password. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <Container fluid>
            <Row className='m-0'>
                <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='px-md-5 ps-2 pt-md-4 pt-2 mb-5'>
                    <div style={{ display: "flex" }}>
                        <img src={logo} style={{ width: "75px", height: "65px" }} />
                        <h2 className='ps-3 fs-1' style={{ display: "flex", color: "#000000", fontFamily: "Inder, var(--default-font-family)", lineHeight: "30px", alignItems: "center", justifyContent: "center", textShadow: "2px 4px 10px gray" }}>THE KENYA NATIONAL EXAMINATION COUNCIL </h2>
                    </div>
                    <div onClick={LoginPage} className='pe-md-5' style={{ display: "flex" }}>
                        <img src={loginArrow} className='w-100' style={{ height: "24px" }} />&nbsp;&nbsp;
                        <p style={{ color: "#000000" }}>Back</p>
                    </div>
                </Col>
            </Row>

            <Container className='mb-5'>


                <Row className='px-5 pt-5'>

                <Col sm={12} md={4} className='mb-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={changePass} className='w-100' />
                </Col>

                    {step === 1 && (
                        <Col md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='login-container mb-4 mb-md-0'>
                                <div className='login-form shadow mb-5 mb-md-3 py-4' style={{ border: "3px solid #ffe500", borderRadius: "15px" }}>
                                    <div className='input'>
                                        <h2>Forgot Password</h2>
                                        <small style={{ display: "flex", justifyContent: "end" }}>Create your new password</small>
                                        <form  onSubmit={saveFun1}>
                                            <input
                                                id='input'
                                                className='mt-3'
                                                type="email"
                                                placeholder='Email Id'
                                                onChange={handleEmail}
                                                required
                                            />
                                            <div className='login-button mt-2'>
                                                <button
                                                    type="submit"
                                                    className='shadow px-2 py-1'
                                                    style={{ borderRadius: "25px", backgroundColor: "rgb(224, 47, 47)", border: "0px", color: "white" }}
                                                    disabled={loading}
                                                    
                                                >
                                                    {loading ? <Spinner animation='border' variant="primary" /> : <p className=' m-0' style={{ fontSize: "14px" }}>Send verification OTP</p>}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}

                    {step === 2 && (
                        <Col md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='login-container mb-4 mb-md-0'>
                                <div className='login-form shadow mb-5 mb-md-3 py-4' style={{ border: "3px solid #ffe500", borderRadius: "15px" }}>
                                    <div className='input'>
                                        <h2>Forgot Password</h2>
                                        <form onSubmit={saveFun}>
                                            <input
                                                id='input'
                                                placeholder='Enter OTP'
                                                maxLength="6"
                                                className='mt-4'
                                                inputMode="numeric"
                                                title='Please enter 6 digit OTP.'
                                                value={otp}
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/\D/g, "");
                                                    setOtp(numericValue);
                                                }}
                                                required
                                            />
                                            <div className='pt-2' style={{ display: 'flex', justifyContent: 'end' }}>
                                                <Nav.Link style={{ color: 'blue' }} onClick={resendOtp}>
                                                    <small className='forgot' style={{ color: 'gray', display: 'flex' }}>
                                                        Didn't receive the code?
                                                        <div style={{ color: 'blue' }} disabled={loading}>{loading ? <Spinner animation='border' variant="primary" /> : <p>&nbsp;Resend</p>}</div>
                                                    </small>
                                                </Nav.Link>
                                            </div>
                                            <div className='login-button mt-2'>
                                                <button
                                                    type="submit"
                                                    className='shadow px-2 py-1'
                                                    style={{ borderRadius: "25px", backgroundColor: "#008080", border: "0px", color: "white" }}
                                                    disabled={loading}
                                                >
                                                    {loading ? <Spinner animation='border' variant="primary" /> : <p className=' m-0' style={{ fontSize: "14px" }}>Confirm OTP & Next</p>}
                                                </button>
                                            </div>
                                        </form>
                                        <div>
                                            <h6 className='text-end py-3 pt-2 m-0 p-0' style={{ color: "#3d3d3d" }}>
                                                {timer > 0 ? `Time remaining: ${Math.floor(timer / 60)}:${(timer % 60).toString().padStart(2, '0')}` : 'OTP Expired'}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}

                    {step === 3 && (
                        <Col md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='login-container mb-4 mb-md-0'>
                                <div className='login-form shadow mb-5 mb-md-3 py-4' style={{ border: "3px solid #ffe500", borderRadius: "15px" }}>
                                    <div className='input'>
                                        <h2>Forgot Password</h2>
                                        <form onSubmit={handleSubmit}>
                                            <div className='input'>
                                                <div className="col-md-12 mt-4">
                                                    <Form.Group controlId="validationCustom06">
                                                        <label htmlFor="password">Create Password:</label>
                                                        <FormControl
                                                            style={input}
                                                            type="password"
                                                            id="password"
                                                            value={password}
                                                            isInvalid={!passwordsMatch}
                                                            onChange={(e) => handlePasswordChange(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form.Group controlId="validationCustom06">
                                                        <label htmlFor="confirmPassword">Confirm Password:</label>
                                                        <FormControl
                                                            style={input}
                                                            type="password"
                                                            id="confirmPassword"
                                                            value={confirmPassword}
                                                            isInvalid={!passwordsMatch}
                                                            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className='login-button mt-4'>
                                                    <button
                                                        type="submit"
                                                        className='shadow px-4 py-1'
                                                        style={{ borderRadius: "25px", backgroundColor: "#008000", border: "0px", color: "white" }}
                                                        disabled={loading}
                                                    >
                                                        {loading ? <Spinner animation='border' variant="primary" /> : <p className=' m-0' style={{ fontSize: "16px" }}>Save</p>}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>

            <div>
                <h6 className='text-center py-3 fixed-bottom m-0 p-0' style={{ color: "#ffe500", backgroundColor: "rgb(47, 47, 65)" }}>2024 DESIGN & DEVELOPED BY | Shrabik Solutions </h6>
            </div>

        </Container>
    )
}

export default KenyaForgotPass